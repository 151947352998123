import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import UploadFileComp from "../../../components/UploadFileComp";
import { IMAGEURL } from "../../../helper/constant";
import { imageSectionSchema, newinSectionSchema } from "../../../schema";
import MasterServices from "../../../ApiServices/MasterServices";
import uploadImageHandler from "../../../helper/uploadImageHandler"



const AddNewInSection = () => {
    const { new_id } = useParams();
    const [singleImageProgress, setSingleImageProgress] = useState(0);
    const [singleImage, setSingleImage] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [selectStatus, setSelectStatus] = useState(null);
    const [editNewInSection, setEditNewInSection] = useState([])
    const navigate = useNavigate();

    const formValues = {
        "h_new_in_redirect_link": "",
        "p_product_id": "",
        "h_new_in_section_active": ""
    }
    const [initialValues, setInitialValues] = useState(formValues);
    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];

    const getSingleNewInSection = async (id) => {
        try {
            const response = await MasterServices.getSingleNewInSectionApi(id);
            console.log("getSingleNewInSection", response)
            if (response?.data?.data?.length > 0) {
                setEditNewInSection(response?.data?.data)
            } else {
                setEditNewInSection([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
        setFieldTouched
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: newinSectionSchema,
        onSubmit: async (values) => {

            const body = {
                ...values,
                h_new_in_section_active: selectStatus?.value ? selectStatus?.value : null,
                p_product_id: values?.p_product_id ? parseInt(values?.p_product_id) : null,

            }

            console.log("body", body)

            if (editNewInSection?.length > 0) {
                editNewinHandler(body)
            } else {
                addNewInHandler(body)
            }
            
        }
    })

    const editNewinHandler = async(body) => {
            try {
    
                const payload = {
                    h_new_in_section_id: new_id ? parseInt(new_id) : null,
                    ...body
                }
                console.log("editNewinHandler", payload)
                setSubmitLoader(true)
                const response = await MasterServices.editNewInSectionApi(payload);
                console.log("body", response)
                if (response?.data?.success === true) {
                    setSubmitLoader(false)
                    toast.success("newin edited Successfully");
                    navigate("/newinsection")
                    clearForm();
                } else {
                    setSubmitLoader(false)
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                setSubmitLoader(false)
                toast.error("An error occurred while editig the editNewinHandler");
            }
        }

     const addNewInHandler = async(payload) => {
            try {
                setSubmitLoader(true)
                const response = await MasterServices.addNewInSectionApi(payload);
                console.log("response", response)
                if (response?.data?.success) {
                    setSubmitLoader(false)
                    toast.success(response?.data?.message)
                    navigate("/newinsection")
                } else {
                    setSubmitLoader(false)
                    toast.info(response?.data?.message)
                }
            }
            catch (error) {
                setSubmitLoader(false)
                toast.error(error?.data?.message)
            }
        }

    useEffect(() => {
            if (editNewInSection?.length > 0) {
                editNewInSection?.forEach((ele) => {
                   
                    setFieldValue("h_new_in_redirect_link", ele?.h_new_in_redirect_link);
                    setFieldValue("p_product_id", ele?.p_product_id);
                    setFieldValue("h_new_in_section_active", ele?.h_new_in_section_active);
                    
    
                    if (ele?.h_new_in_section_active) {
                        statusOption?.length > 0 && statusOption.forEach((item) => {
                            if (item?.value === ele?.h_new_in_section_active) {
                                setSelectStatus({
                                    label: item?.label,
                                    value: item?.value
                                })
                            }
                        })
                    }
    
                    
                })
            }
        }, [editNewInSection])

     useEffect(() => {
            const fetchData = async () => {
                if (new_id) {
                    await getSingleNewInSection(new_id)
                }
            }
            fetchData()
        }, [new_id])

        const clearForm = () => {
            setFieldValue("h_new_in_redirect_link", "");
            setFieldValue("p_product_id", "");
            setFieldValue("h_new_in_section_active", "");
            
            navigate("/newinsection")
        }

    return (
        <>
          <div className="">
                <h1 className="text-xl font-semibold capitalize text-gray-900">{new_id ? "Edit NewIn" : "Add NewIn"}</h1>
            </div>

            <form className="py-5 flex flex-col gap-5 " onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>

                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">NewIn Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Redirect Link
                            </label>
                            <div className="">
                                <input
                                    value={values.h_new_in_redirect_link}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_new_in_redirect_link"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:https://"
                                />
                                {errors.h_new_in_redirect_link && touched.h_new_in_redirect_link && (
                                    <div className="text-red-600 text-sm">{errors.h_new_in_redirect_link}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Product Id
                            </label>
                            <div className="">
                                <input
                                    value={values.p_product_id}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_product_id"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:Heading"
                                />
                                {errors.p_product_id && touched.p_product_id && (
                                    <div className="text-red-600 text-sm">{errors.p_product_id}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Active/Inactive
                            </label>
                            <div>

                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectStatus}
                                    options={statusOption}
                                   
                                    onChange={(selectedOption) => {
                                        setSelectStatus(selectedOption);
                                        setFieldValue("h_new_in_section_active", selectedOption?.value || ""); // Update formik value
                                        if (!selectedOption) {
                                            setFieldError("h_new_in_section_active", "Status is required");
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!selectStatus) {
                                            setFieldError("h_new_in_section_active", "Status is required");
                                        }
                                    }}
                                    styles={customStyles}
                                    name="h_new_in_section_active"
                                    className="basic-single sm:text-sm"

                                />
                                {errors.h_new_in_section_active && touched.h_new_in_section_active && (
                                    <div className="text-red-600 text-sm">{errors.h_new_in_section_active}</div>
                                )}

                            </div>
                        </div>
                      
                    </div>
                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="submit"

                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddNewInSection