import React from "react";
import { Oval, RotatingLines } from "react-loader-spinner";

export const RotatingLineSpinner = () => {
    return (
        <>
            <div className="flex justify-center items-center">

                <RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>

    )
}
 const Spinner = () => {
    return (
        <>
            <div className="flex justify-center items-center h-screen bg-transparent opacity-80">

                <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="#a96a37"
                    secondaryColor="#ebac78"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>

    )
}

export const MainLoader = () => {
    return (
        <>
            <div className="flex justify-center items-center">

                <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="#a96a37"
                    secondaryColor="#ebac78"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>

    )
}

export default Spinner;