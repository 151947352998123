import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import UploadFileComp from "../../../components/UploadFileComp";
import { IMAGEURL } from "../../../helper/constant";
import { designerSectionSchema, imageSectionSchema } from "../../../schema";
import MasterServices from "../../../ApiServices/MasterServices";
import uploadImageHandler from "../../../helper/uploadImageHandler"


const AddDesignerSection = () => {
    const { designer_id } = useParams();
    const [singleImageProgress, setSingleImageProgress] = useState(0);
    const [singleImage, setSingleImage] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [selectStatus, setSelectStatus] = useState(null);
    const [editDesignerSection, setEditDesignerSection] = useState([])
    const navigate = useNavigate();

    const formValues = {

        "h_designers_section_heading": "",
        "h_designers_section_order": "",
        "h_designers_redirect_link": "",
        "h_designers_in_section_active": "",
        "h_iamge_url": ""
    }
    const [initialValues, setInitialValues] = useState(formValues);
    const statusOption = [
        { value: 'Y', label: 'Active' },
        { value: 'N', label: 'Inactive' }
    ];

    const getSingleDesignerSection = async (id) => {
        try {
            const response = await MasterServices.getSingleDesignerSectionApi(id);
            console.log("getSingleDesignerSection", response)
            if (response?.data?.data?.length > 0) {
                setEditDesignerSection(response?.data?.data)
            } else {
                setEditDesignerSection([])
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
        setFieldTouched
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: designerSectionSchema,
        onSubmit: async (values) => {

            const body = {
                ...values,
                h_designers_section_order: values?.h_designers_section_order ? parseInt(values?.h_designers_section_order) : null,
                h_designers_in_section_active: selectStatus?.value ? selectStatus?.value : null,
                h_iamge_url: singleImage?.includes("https") ? singleImage : IMAGEURL + singleImage
            }

            console.log("body", singleImage)

            if (editDesignerSection?.length > 0) {
                editDesignerHandler(body)
            } else {
                addDesignerHandler(body)
            }
            console.log("body", body)
        }
    })

    const editDesignerHandler = async(body) => {
        try {

            const payload = {
                h_designers_section_id: designer_id ? parseInt(designer_id) : null,
                ...body
            }
            console.log("editDesignerHandler", payload)
            setSubmitLoader(true)
            const response = await MasterServices.editDesignerSectionApi(payload);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success(" Edited Successfully");
                navigate("/designersection")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while editig the editDesignerHandler");
        }
    }
    const addDesignerHandler = async(payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addDesignerSectionApi(payload);
            console.log("response", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                navigate("/designersection")
            } else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
        }
    }

    useEffect(() => {
        if (editDesignerSection?.length > 0) {
            editDesignerSection?.forEach((ele) => {
                setFieldValue("h_designers_section_heading", ele?.h_designers_section_heading);
                setFieldValue("h_designers_section_order", ele?.h_designers_section_order);
                setFieldValue("h_designers_redirect_link", ele?.h_designers_redirect_link);
                setFieldValue("h_designers_in_section_active", ele?.h_designers_in_section_active);
                setFieldValue("h_iamge_url", ele?.h_iamge_url);


                if (ele?.h_designers_in_section_active) {
                    statusOption?.length > 0 && statusOption.forEach((item) => {
                        if (item?.value === ele?.h_designers_in_section_active) {
                            setSelectStatus({
                                label: item?.label,
                                value: item?.value
                            })
                        }
                    })
                }

                setSingleImage(ele?.h_iamge_url)
            })
        }
    }, [editDesignerSection])

    useEffect(() => {
        const fetchData = async () => {
            if (designer_id) {
                await getSingleDesignerSection(designer_id)
            }
        }
        fetchData()
    }, [designer_id])



    const handleFileChange = async (e, setImageState) => {

        const width = 375;
        const height = 588;
       
        uploadImageHandler(e,setImageState,width,height,setFieldValue,setSingleImageProgress)

     
    }

    const clearForm = () => {
        setFieldValue("h_designers_section_heading", "");
        setFieldValue("h_designers_section_order", "");
        setFieldValue("h_designers_redirect_link", "");
        setFieldValue("h_designers_in_section_active", "");
        setFieldValue("h_iamge_url", "");
        setSelectStatus(null);
        setSingleImage(null);
        navigate("/designersection")
    }

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold capitalize text-gray-900">{designer_id ? "Edit Image" : "Add Image"}</h1>
            </div>
            <form className="py-5 flex flex-col gap-5 " onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200">
                    <div className="p-5 border-b-[1px] border-[#ebac78]">
                        <h1 className="text-md font-semibold text-black">Designer Information</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-b-lg p-5 py-8 shadow-sm">
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Heading
                            </label>
                            <div className="">
                                <input
                                    value={values.h_designers_section_heading}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_designers_section_heading"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:Heading"
                                />
                                {errors.h_designers_section_heading && touched.h_designers_section_heading && (
                                    <div className="text-red-600 text-sm">{errors.h_designers_section_heading}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Order
                            </label>
                            <div className="">
                                <input
                                    value={values.h_designers_section_order}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_designers_section_order"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:1 or 2"
                                />
                                {errors.h_designers_section_order && touched.h_designers_section_order && (
                                    <div className="text-red-600 text-sm">{errors.h_designers_section_order}</div>
                                )}

                            </div>
                        </div>

                        <div>
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Redirect Link
                            </label>
                            <div className="">
                                <input
                                    value={values.h_designers_redirect_link}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="h_designers_redirect_link"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="eg:https://"
                                />
                                {errors.h_designers_redirect_link && touched.h_designers_redirect_link && (
                                    <div className="text-red-600 text-sm">{errors.h_designers_redirect_link}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Active/Inactive
                            </label>
                            <div>

                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"
                                    value={selectStatus}
                                    options={statusOption}
                                    // onChange={(selectedOption) => {
                                    //     setSelectStatus(selectedOption)
                                    // }}
                                    onChange={(selectedOption) => {
                                        setSelectStatus(selectedOption);
                                        setFieldValue("h_designers_in_section_active", selectedOption?.value || ""); // Update formik value
                                        if (!selectedOption) {
                                            setFieldError("h_designers_in_section_active", "Status is required");
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!selectStatus) {
                                            setFieldError("h_designers_in_section_active", "Status is required");
                                        }
                                    }}
                                    styles={customStyles}
                                    name="h_designers_in_section_active"
                                    className="basic-single sm:text-sm"

                                />
                                {errors.h_designers_in_section_active && touched.h_designers_in_section_active && (
                                    <div className="text-red-600 text-sm">{errors.h_designers_in_section_active}</div>
                                )}

                            </div>
                        </div>
                        <div className="sm:col-span-2 w-full"></div>
                        <div className="sm:col-span-2 w-full">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Image banner<span className="text-[12px] text-blue-500 ml-2">(Note:Please upload an image with 375*588)</span>
                            </label>
                            <div className=" ">
                                {
                                    singleImage ?
                                        <div className="flex items-center justify-center w-full h-[450px] rounded-lg px-1 py-1  border-2 border-dashed border-gray-400 bg-gray-200">
                                            <div className="relative ">
                                                <div className="border border-black w-full h-[420px]   rounded-lg overflow-hidden relative  flex justify-center items-center">
                                                    <img src={`${singleImage?.includes("https") ? singleImage : IMAGEURL + singleImage}`} className=" top-0 left-0 w-fit h-full  opacity-30" alt="name" />

                                                    <div className="absolute top-0  w-full   flex justify-center items-center h-full">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                            // onClick={() => setSingleImage(null)}
                                                            onClick={() => {
                                                                setSingleImage(null);
                                                                setSingleImageProgress(0);
                                                                setFieldValue("h_iamge_url", "");
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="flex  w-full flex-col">
                                            {
                                                parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                    <div className="w-full h-60 flex justify-center items-center">
                                                        <Spinner />
                                                    </div>
                                                    :
                                                    <label className="w-full h-[450px] rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                        <span className="text-md text-gray-500">* Upload Image</span>
                                                        <input type="file" className="hidden" accept="image/*"
                                                            // onChange={(e) => handleFileChange(e, setSingleImage)}
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                handleFileChange(e, setSingleImage);
                                                                setFieldValue("h_iamge_url", file?.name || "");
                                                                setFieldTouched("h_iamge_url", true, false);


                                                                if (!file) {
                                                                    setFieldError("h_iamge_url", "Image is required");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (!values.h_iamge_url) {
                                                                    setFieldError("h_iamge_url", "Image is required");
                                                                }
                                                            }}
                                                        />


                                                    </label>
                                            }
                                            {errors.h_iamge_url && touched.h_iamge_url && (
                                                <div className="text-red-600 text-sm">{errors.h_iamge_url}</div>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>

                    </div>

                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        type="submit"

                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddDesignerSection