import React, { useState, useEffect, Fragment ,useContext} from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toast";
import Table from "../../../components/tables/table";
import { Description } from "@headlessui/react";
import { category_columns, subCategory_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { Dialog, Transition } from '@headlessui/react';
import Spinner from "../../../components/Spinner";
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";
import { CustomContext } from "../../../context/MyContext";


const Category = () => {
    const pages = [{ title: "Category", href: "/category-page", module_id: 1 }];
    const [categoryData, setCategoryData] = useState([]);
    const [filterCategoryData, setFilterCategoryData] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectStatus, setSelectStatus] = useState(null);
    const [selectCategoryType, setSelectCategoryType] = useState(null);
    const { rolePermissionData } = useContext(CustomContext);
    const [checkPermission, setCheckPermission] = useState([])

    // let [isOpen, setIsOpen] = useState(false);
    const [subCatData, setSubCatData] = useState({
        isOpen: false,
        data: []
    })

    useEffect(() => {
        const fetchData = async () => {
            // await getCategory()
            await getAllCatAndSubCat()
        }

        fetchData()
    }, [])

    const statusOption = [
        {
            label: "Active",
            value: "Y"
        },
        {
            label: "Inactive",
            value: "N"
        },
    ]
    const categoryType = [
        {
            label: "Category",
            value: "1"
        },
        {
            label: "SubCategory",
            value: "2"
        },
    ]

    const getAllCatAndSubCat = async () => {
        try {

            const response = await MasterServices.getAllCatAndSubCat();
            //console.log("getCategory", response)
            if (response?.data?.data?.length > 0) {
                //console.log("getCategory",response?.data?.data)
                console.log("getAllCatAndSubCat", response)
                setCategoryData(response?.data?.data);
                setFilterCategoryData(response?.data?.data)
                setLoading(false)
            }
        } catch (error) {
            toast.error("error in getAllCatAndSubCat")
        }
    }

    const subCatDataHandler = (data) => {
        //console.log("subCatDataHandler", data);

        setSubCatData({
            isOpen: true,
            data: data
        })

    }

    useEffect(() => {
        if (selectStatus || selectCategoryType) {
            console.log("filterCategoryData", filterCategoryData)
            const filterData = filterCategoryData.filter((ele) => {
                //const dateFilter = moment(ele?.p_product_attributes_attribute_created_at).format("YYYY-MM-DD");
                // const startDate = dateInput?.startDate ?  moment(dateInput?.startDate).format("YYYY-MM-DD"):null;
                // const endDate = dateInput?.endDate ?  moment(dateInput?.endDate).format("YYYY-MM-DD"):null;
                const categoryId = ele?.p_product_category_id;
                const subcategoryId = ele?.p_product_sub_category_id;
                const selectCatType = selectCategoryType?.label ? selectCategoryType?.label.toLowerCase() : null;
                const dataCatType = ele?.categoryType ? ele?.categoryType?.toLowerCase() : null
                const statusFlag = selectStatus?.value ? selectStatus?.value : null

                const typeMatches = selectCatType ? dataCatType === selectCatType : true
                const statusMatches = statusFlag ? ele?.p_category_active === statusFlag || ele?.p_sub_category_active === statusFlag : true
                //const dateMatches = (!startDate || startDate <=dateFilter) && (!endDate || endDate >=dateFilter);


                return statusMatches && typeMatches

            })

            if (filterData?.length > 0) {
                setCategoryData(filterData)
            }
            else {
                setCategoryData([])
            }
        }
    }, [selectStatus, selectCategoryType])


    useEffect(() => {
        if (rolePermissionData?.length > 0) {
            console.log("rolePermissionData", rolePermissionData);
            const filterData = rolePermissionData?.filter((ele) => ele?.module_name === "Category");
            console.log("filterData", filterData);
            setCheckPermission(filterData)
        }

    }, [rolePermissionData]);

    const clearForm = () => {

        setSelectStatus(null);
        setCategoryData(filterCategoryData);
        setSelectCategoryType(null)


    }

    const downloadExcelBtn = () => {
        console.log("downloadExcelBtn", categoryData)
        const filterData = categoryData?.map((ele) => {
            const subname = ele?.subCategory?.length > 0
                ? ele.subCategory
                    .map(sub => sub?.p_sub_category_name)
                    .filter(Boolean)
                    .join(", ")
                : "";

            const isCategory = ele?.categoryType?.toLowerCase() === "category";
            //console.log("subname", subname);

            const result = {
                Id: isCategory ? ele?.p_product_category_id : ele?.p_product_sub_category_id,
                Name: isCategory ? ele?.p_category_name : ele?.p_sub_category_name,
                Status: isCategory ? ele?.p_category_active : ele?.p_sub_category_active,
                // CreatedAt: moment(ele?.m_order_created_at).format("YYYY-MM-DD")
            };

            if (isCategory && subname) {
                result.subCategory = subname;
            }

            return result
        })

        downloadSheet(filterData, "category")

        //console.log("filterData",filterData)
    }

    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Category List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Category.</p>
                </div>
                {
                    checkPermission[0]?.is_add === "Y" && (
                        <Link

                            to="/add-category"
                            className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                        >
                            ADD CATEGORY
                        </Link>
                    )
                }
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Category
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectStatus}
                                options={statusOption}
                                onChange={(selectedOption) => {
                                    setSelectStatus(selectedOption)
                                }}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Type
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectCategoryType}
                                options={categoryType}
                                onChange={(selectedOption) => {
                                    setSelectCategoryType(selectedOption)
                                }}
                                name="selectCategoryType"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="date"
                                // value={startDate}
                                // onChange={(e) => {
                                //     const startDate = e.target.value;
                                //     setStartDate(startDate);
                                //     setFilters((prevFilters) => ({
                                //         ...prevFilters,
                                //         startDate: startDate,
                                //     }));
                                // }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div> */}
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="date"
                                // value={endDate}
                                // onChange={(e) => {
                                //     const endDate = e.target.value;
                                //     setEndDate(endDate);
                                //     setFilters((prevFilters) => ({
                                //         ...prevFilters,
                                //         endDate: endDate,
                                //     }));
                                // }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div> */}

                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>

            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={category_columns({ subCatDataHandler,checkPermission })}
                        data={categoryData}

                    />
            }
            <Transition appear show={subCatData.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={() => setSubCatData({
                    isOpen: false,
                    data: []
                })}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-[500px]">
                                    <Table
                                        columns={subCategory_columns()}
                                        data={subCatData?.data}
                                    />
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Category