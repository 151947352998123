import React, { useEffect, useState, useCallback } from "react";
import Attributes from "./Attributes";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toast";
import { BASEURL, IMAGEURL, VIDEOURL } from "../../../helper/constant.js";
import UploadFileComp from "../../../components/UploadFileComp";
import ProgressBar from "../../../components/ProgressBar.jsx";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner.jsx";
import { productSchema, product_schema } from "../../../schema/index.jsx";
import { object } from "yup";
import { customStyles } from "../../../helper/customStyles.js";
import debounce from "../../../helper/debounce.js";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';




const AddProduct = () => {

    const [categories, setCategories] = useState([])
    const [brandData, setBrandData] = useState([])
    const [attributes, setAttributes] = useState([])
    const [productImage, setProductImage] = useState("")
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([])
    const [statusOption, setStatusOption] = useState(null)
    const [colorValue, setColorValue] = useState({})
    const [variationCheck, setVariationCheck] = useState([])
    const [attributeData, setAttributeData] = useState([])
    const [progress, setProgress] = useState(0);
    const [singleImageProgress, setSingleImageProgress] = useState(0)
    const [mulImageProgress, setMulImageProgress] = useState(0)
    const [singleImage, setSingleImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(true)
    const [multipleImages, setMultipleImages] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [defaultUrl, setDefaultUrl] = useState("https://revamp-revivify.enpointe.io/")
    const [permaString, setPermaString] = useState("")
    const [editProduct, setEditProduct] = useState([])
    const [selectAttribute, setSelectAttribute] = useState([]);
    const [variationData, setVariationData] = useState([])
    let [attributesOption, setAttributesOption] = useState([]);
    const [parentAttribute, setParentAttribute] = useState([]);
    const [selectChildValue, setSelectChildValue] = useState({});
    const [childInputValue, setChildInputValue] = useState([]);
    const [loopVariation, setLoopVariation] = useState([]);
    const [metaImage, SetMetaIMage] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false)
    let [disableSku, setDisableSku] = useState({
        checked: false,
        errorName: "SKU Exits"
    })
    const [selectProductOption, setSelectProductOption] = useState({
        value: 1,
        label: "Simple Product"
    },)
    const [error, setError] = useState({ statusError: "", productError: "" });
    const [flagParentAttribute, setFlagParentAttribute] = useState(false)
    const [valid, setValid] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const viewFlag = location.pathname.includes("viewproduct")
    const { product_id } = useParams();
    // console.log("location", {
    //     viewFlag,
    //     location
    // })

    const initialFormState = selectChildValue?.keystatic?.flatMap((item, parentIndex) =>
        item?.data?.map((dd, childIndex) => ({
            price: 0,
            stock: 0,
            description: '',
            parentIndex,
            childIndex,
            childAttributeId: dd.p_attribute_value_id

        }))
    ) || [];


    const [variationForm, setVariationForm] = useState([]);
    const [fullUrl, setFullUrl] = useState('');
    const [searchBrand, setSearchBrand] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [scheduldateTime, setScheduleDateTime] = useState("")
    const formValues =
    {
        p_product_name: "",
        parmalink: "",
        p_product_desc: "",
        p_product_short_desc: "",
        p_original_purchase_price: 0,
        p_meta_title: "",
        p_meta_desc: "",
        p_meta_tags: "",
        p_gen_regular_price: 0,

        p_gen_sale_price: 0,

        p_inv_sku: "",

        p_inv_manage_stock: false,
        p_inv_stock_quantity: 0,
        p_inv_sold_individually: false,
        p_var_regular_price: 0,
        p_var_description: "",
        p_var_stock_quantity: 0,
        p_product_schedule_date: "",
        ship_within: "",

    }

    const [initialValues, setInitialValues] = useState(formValues)
    const [permaLink, setPermaLink] = useState({
        category: "",
        shortDescription: "",
        designer: "",
        skuValue: ""
    })


    useEffect(() => {

        const fetchData = async () => {
            await getCategories();
            await getBrands();
            await getAttributes();
        }
        fetchData()
    }, [])


    useEffect(() => {
        if (categories?.length > 0 && brandData?.length > 0 && attributes?.length > 0) {
            setLoading(false);
        }
    }, [categories, brandData, attributes]);


    const statusDataOption = [
        {
            value: 0,
            label: "Draft"
        },
        {
            value: 1,
            label: "Publish"
        },
        {
            value: 2,
            label: "Schedule"
        }
    ]
    const ProductSelectOption = [
        {
            value: 1,
            label: "Simple Product"
        },
        {
            value: 2,
            label: "Variation Product"
        },
    ]
    const getCategories = async () => {
        const response = await MasterServices.getAllCategory();
        console.log("getCategories", response)
        if (response?.data?.data?.length > 0) {
            setCategories(response?.data?.data)
        } else {
            setCategories([])
        }
    }
    const getBrands = async () => {
        const response = await MasterServices.getAllBrands();
        console.log("getBrands", response)
        if (response?.data?.data?.length > 0) {
            setBrandData(response?.data?.data)
        } else {
            setBrandData([])
        }
    }

    const getAttributes = async () => {
        const response = await MasterServices.getAllAttributes();
        console.log("getAttributes", response)
        if (response?.data?.data?.length > 0) {
            setAttributes(response?.data?.data)
            let attributesOpt = response?.data?.data?.length > 0 && response?.data?.data.map((ele, index) => ({
                ...ele,
                value: ele?.p_product_attribute_id,
                label: ele?.p_product_attribute_name
            }));
            console.log("jayAttr",attributesOpt)
            setAttributesOption(attributesOpt)

        } else {
            setAttributes([])
        }
    }


    const handleRemoveImage = (indexToRemove) => {
        // e.preventDefault();
        setMultipleImages(prevImages => {

            const updatedImages = prevImages.filter((_, index) => index !== indexToRemove);

            return updatedImages;
        });
    };

    const handleSingleImageUpload = async (event, setImageState) => {
        const file = event.target.files[0];
        if (file) {

            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        event.target.value = ''; // Clear the file input
    };

    const handleMetaImageUpload = async (event, setImageState) => {
        const file = event.target.files[0];
        if (file) {

            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        event.target.value = ''; // Clear the file input
    }
    const handleVideoUpload = async (event, setVideoState) => {
        const file = event.target.files[0];
        if (file) {

            await UploadFileComp(file, setVideoState, setProgress);
        }
        event.target.value = ''; // Clear the file input
    };

    const handleMultipleImagesUpload = async (event, setImagesState) => {
        const files = event.target.files;
        if (files.length > 0) {
            let uploadedFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                await UploadFileComp(file, (fileName) => {
                    if (fileName) {
                        console.log("UploadFileComp", fileName)
                        uploadedFiles.push(fileName);
                    }

                }, setMulImageProgress);

            }
            setImagesState((prevState) => [...prevState, ...uploadedFiles]);
        }
        event.target.value = '';
    };

    const getSingleProduct = async (id) => {
        try {

            const response = await MasterServices.getProductById(id);
            console.log("getSingleProduct", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditProduct(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            if (product_id) {
                await getSingleProduct(product_id)
            }
        }
        fetchData()
    }, [product_id])


    useEffect(() => {

        if (editProduct?.length > 0) {
            editProduct.forEach((item) => {
                const customDate = item?.p_product_schedule_dates ? editFormatDateTime(item?.p_product_schedule_dates) : null;
                //console.log("customDate", customDate, item?.p_product_schedule_dates)
                setInitialValues({
                    p_product_name: item?.p_product_name,
                    p_product_desc: item?.p_product_desc,
                    p_product_short_desc: item?.p_product_short_desc,
                    p_original_purchase_price: item?.p_product_eep_price,
                    p_meta_title: item?.p_meta_title,
                    p_meta_desc: item?.p_meta_desc,
                    p_meta_tags: item?.p_meta_tags,
                    p_gen_regular_price: item?.p_product_price,
                    p_gen_sale_price: item?.p_sale_price,
                    p_inv_sku: item?.p_product_sku,
                    p_inv_manage_stock: item?.p_in_stok === "Y" ? true : false,
                    p_inv_stock_quantity: item?.p_stok_quantity,
                    p_inv_sold_individually: item?.p_product_is_sold_individually === "Y" ? true : false,
                    p_var_regular_price: 0,
                    p_var_sale_price: 0,
                    p_var_stock_quantity: 0,
                    //p_product_schedule_date: item?.p_product_schedule_dates ? item?.p_product_schedule_dates : null,
                   
                    ship_within: item?.ship_within ? item?.ship_within : ""
                })

                if (item?.p_product_parmalink) {
                    setFullUrl('')
                }

                if(item?.p_product_schedule_dates){
                    editFormatDateTime(item?.p_product_schedule_dates)
                }
                if (ProductSelectOption.some(ele => ele.value === item?.p_product_type_id)) {

                    setSelectProductOption({
                        value: item?.p_product_type_id,
                        label: item?.p_product_type_id === 2 ? "Variation Product" : "Simple Product",
                    })
                }

                setVideo(item?.p_product_video)

                if (item?.p_image_gallery) {
                    const imageGallery = item?.p_image_gallery.split(',');
                    console.log("imageGallery", imageGallery);


                    setMultipleImages(imageGallery)
                }

                if (item?.p_product_image1) {
                    // const editimage = item?.p_product_image1.split('images/')
                    const editimage = item?.p_product_image1
                    setSingleImage(editimage)

                }

                // if (item?.m_designers_id) {

                //     setSelectedBrand((prev) => ({
                //         ...prev,
                //         brandID: parseInt(item?.m_designers_id)
                //     }))
                // }
                if (statusDataOption.some(ele => ele.label.toLocaleLowerCase() === item?.p_porducts_status?.toLocaleLowerCase())) {

                    setStatusOption((prev) => ({
                        ...prev,
                        label: item?.p_porducts_status
                    }))
                }


                let newColorValue = []
                if (item?.p_product_attributes) {
                    const attData = item?.p_product_attributes;
                    console.log("attData", attData)
                    let newAttributes = [];
                    let newOptions = []

                    if (attributesOption?.length > 0) {
                        let inputChildValue = []
                        attributesOption.forEach((ele, index) => {

                            if (attData.some(aa => aa?.p_attribute_key_id !== ele?.p_product_attribute_id)) {
                                newOptions.push({
                                    ...ele,
                                    id: ele?.p_product_attribute_id,
                                    label: ele?.p_product_attribute_name
                                });
                            }

                            if (attData.some(aa => aa?.p_attribute_key_id === ele?.p_product_attribute_id)) {
                                setFlagParentAttribute(true)
                                newAttributes.push({
                                    ...ele,
                                    id: ele?.p_product_attribute_id,
                                    label: ele?.p_product_attribute_name
                                });
                            }

                            if (ele?.p_child_attributes?.length > 0) {
                                //console.log("pppp",ele)
                                ele?.p_child_attributes.forEach((pc) => {
                                    attData.forEach((aa) => {

                                        aa?.data?.forEach((pp) => {
                                            if (pp?.p_child_attribute_id === pc?.p_child_attribute_id) {

                                                inputChildValue.push({
                                                    childId: pc?.p_child_attribute_id,
                                                    inputValue: pp?.inputValue
                                                })
                                            }
                                        })
                                    })
                                })
                            }

                        })

                        setChildInputValue(inputChildValue)
                        console.log("inputChildValue", inputChildValue)
                    }



                    if (attData?.length > 0) {
                        let varCheck = [];

                        attData.forEach((ele, ind) => {
                            const updatedData = ele?.data?.map(item => {
                                return {
                                    ...item,
                                    label: item?.p_attribute_value_name || item?.p_child_attribute_name,
                                    value: item?.p_attribute_value_id || item?.p_child_attribute_id
                                };
                            });
                            //console.log("updatedData",updatedData)
                            newColorValue.push({
                                p_product_attribute_id: ele?.p_attribute_key_id,
                                data: updatedData,
                                parentIndex: ind
                            })

                            varCheck.push({
                                checked: ele?.variationCheck,
                                parentId: ele?.p_attribute_key_id
                            })

                        })
                        console.log("varCheck", varCheck)
                        setVariationCheck(varCheck)
                    }


                    setSelectChildValue({
                        keystatic: newColorValue
                    })
                    console.log("newOptions", newOptions)
                    //setAttributesOption(newOptions)
                    setParentAttribute(newAttributes);

                }

                if (item?.p_category_id || item?.p_sub_category_id) {
                    const categoryIds = item.p_category_id.split(";") || [];
                    const subCategoryIds = item?.p_sub_category_id?.split(";") || [];
                    let updatedCat = [];

                    if (Array.isArray(categories)) {
                        categories.forEach((ele) => {
                            if (categoryIds.includes(ele?.p_category_name)) {
                                let subcat = [];
                                if (Array.isArray(ele?.sub_category)) {
                                    ele?.sub_category?.forEach((sub) => {
                                        if (subCategoryIds.includes(sub?.cat_name)) {
                                            subcat.push({
                                                subcatname: sub?.cat_name,
                                                ischecked: true,
                                                subcatid: sub?.id
                                            });
                                        }
                                    });
                                }
                                updatedCat.push({
                                    catid: ele?.category_id,
                                    catname: ele?.p_category_name,
                                    ischecked: true,
                                    subcat: subcat,
                                });
                            }
                        });
                    }
                    console.log("updatedCat", updatedCat)
                    setSelectedCategories(updatedCat)
                }

                if (item?.p_designers?.length > 0) {
                    // const brandIds = item.m_designers_id.split(";") || [];
                    // const subBrandIds = item?.p_sub_category_id?.split(";") || [];
                    //console.log("p_designers",item?.p_designers)
                    let updatedBrand = [];
                    //const tempId = parseInt(item?.m_designers_id)
                    item?.p_designers?.forEach((ele) => {
                        if (brandData?.find(pp => pp?.m_designers_id === ele?.brandId)) {
                            let subBrand = [];
                            if (ele?.subBrand?.length > 0) {
                                ele?.subBrand?.forEach((sub) => {
                                    subBrand.push({
                                        // subBrandname: sub?.m_name,
                                        subBrandId: sub?.subBrandId,
                                        ischecked: true,
                                    })
                                })

                            }
                            updatedBrand.push({
                                //brandName: ele?.m_name,
                                brandId: ele?.brandId,
                                ischecked: true,
                                subBrand: subBrand,
                            });
                        }
                    })

                    // if (Array.isArray(brandData)) {
                    //     brandData.forEach((ele) => {
                    //         // if (brandIds.includes(ele?.m_name)) {
                    //             let subBrand = [];
                    //             if (Array.isArray(ele?.subdesigners)) {
                    //                 ele.subdesigners.forEach((sub) => {
                    //                     if (subBrandIds.includes(sub?.m_name)) {
                    //                         subBrand.push({
                    //                             subBrandname: sub?.m_name,
                    //                             subBrandId:sub?.m_sub_designers_id,
                    //                             ischecked: true,
                    //                         });
                    //                     }
                    //                 });
                    //             }
                    //             updatedBrand.push({
                    //                 brandName: ele?.m_name,
                    //                 brandId:ele?.m_designers_id,
                    //                 ischecked: true,
                    //                 subBrand: subBrand,
                    //             });
                    //         //}
                    //     });

                    //     // brandData.forEach((ele) => {
                    //     //     if (ele?.m_designers_id === tempId) {
                    //     //         updatedBrand.push({
                    //     //             brandName: ele?.m_name,
                    //     //             brandId: ele?.m_designers_id,
                    //     //             ischecked: true,
                    //     //             subBrand: [],
                    //     //         });
                    //     //     }
                    //     // })
                    // }
                    console.log("updatedBrand", updatedBrand)
                    setSelectedBrand(updatedBrand)
                }

                if (item?.p_variation_product) {
                    if (item?.p_variation_product?.length > 0) {
                        console.log("p_variation_product", item?.p_variation_product)
                        //setLoopVariation(item?.p_variation_product)
                        const filterData = item?.p_variation_product?.length > 0 && item?.p_variation_product.map((ele) => {
                            return {
                                price: parseInt(ele?.price),
                                stock: parseInt(ele?.stock),
                                description: ele?.description,
                                p_product_attribute_id: parseInt(ele?.p_product_attribute_id),
                                childAttributeId: parseInt(ele?.p_attribute_value_id),
                                childAttributeName: ele?.childAttribute

                            }

                        })
                        console.log("filterDatjaya", filterData)

                        setVariationForm(filterData)
                        //setVariationData(filterData)
                    } else {
                        setVariationForm([]);
                        //setVariationData([])
                    }

                }


            })
        }

    }, [editProduct, attributes])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        //validationSchema: productSchema,

        onSubmit: async (values) => {

            const body = {
                ...values,
                parmalink: fullUrl ? fullUrl : "",
                p_product_image: singleImage ? singleImage : null,
                p_product_category: selectedCategories?.length > 0 ? selectedCategories : [],
                p_product_designer: selectedBrand?.length > 0 ? selectedBrand[0]?.brandId : null,
                p_product_status: statusOption?.label ? statusOption : null,
                p_image_gallery: multipleImages ? multipleImages : [],
                p_product_video: video ? video : null,
                p_product_type: selectProductOption ? selectProductOption : null,
                p_product_attributes: attributeData?.length > 0 ? attributeData : [],
                p_variation_product: variationForm?.length > 0 ? variationForm : [],
                p_meta_image: metaImage ? metaImage : null,
                p_designers: selectedBrand?.length > 0 ? selectedBrand : [],
                p_product_schedule_date: statusOption?.label === "Draft" || statusOption?.label === "Publish" ? null : scheduldateTime
            }


            console.log("bodyyyyyyy", body)
            if (editProduct?.length > 0 && product_id) {
                editProductSubmit(body)
            } else {
                addProductSubmit(body)
            }

        }
    })

    const addProductSubmit = async (body) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addProducts(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("Product Added Successfully");
                navigate("/product-page")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while adding the product");
        }

    }

    const editProductSubmit = async (body) => {

        let data = body;
        data = { ...data, porduct_id: product_id }


        try {
            setSubmitLoader(true)
            const response = await MasterServices.editProducts(data);
            console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("Product Edited Successfully");
                navigate("/product-page")
                clearForm();
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while Edited the product");
        }

    }

    const handleCategoryChange = (category, isChecked, id) => {

        setSelectedCategories(prevState => {
            let updatedCategories = [...prevState];

            if (isChecked) {
                const existingCategory = updatedCategories.find(cat => cat.catname === category);
                if (!existingCategory) {
                    updatedCategories.push({
                        catname: category,
                        catid: id,
                        ischecked: true,
                        subcat: []
                    });
                }
            } else {
                updatedCategories = updatedCategories.filter(cat => cat.catname !== category);
            }

            return updatedCategories;
        });

    };

    const handleSubCategoryChange = (category,catID, subCategory, isChecked, subID) => {

        setSelectedCategories(prevState => {
            let updatedCategories = [...prevState];

            let categoryIndex = updatedCategories.findIndex(cat => cat.catname === category);

            if (categoryIndex === -1) {
                updatedCategories.push({
                    catname: category,
                    catid: catID,
                    ischecked: false,
                    subcat: []
                });
                categoryIndex = updatedCategories.length - 1;
            }

            let updatedCategory = { ...updatedCategories[categoryIndex] };

            let updatedSubCategories = updatedCategory.subcat.filter(sub => sub.subcatname !== subCategory);

            if (isChecked) {
                updatedSubCategories.push({
                    subcatname: subCategory,
                    subcatid: subID,
                    ischecked: true
                });
            }

            updatedCategory.subcat = updatedSubCategories;
            updatedCategory.ischecked = updatedSubCategories.length > 0 ? true : true;

            updatedCategories[categoryIndex] = updatedCategory;

            return updatedCategories;
        });
    };

    const handleBrandChange = async (brandname, isChecked, brandID) => {

        setSelectedBrand(prevState => {
            let updatedBrand = [...prevState];

            if (isChecked) {
                const existingBrand = updatedBrand.find(cat => cat.brandId === brandID);
                if (!existingBrand) {
                    updatedBrand.push({
                        brandName: brandname,
                        brandId: brandID,
                        ischecked: true,
                        subBrand: []
                    });
                }
            } else {
                updatedBrand = updatedBrand.filter(cat => cat.brandId !== brandID);
            }

            return updatedBrand;
        });
    }

    const handleSubBrandChange = (brandname, brandID, subBrand, isChecked, subBrandID) => {
        setSelectedBrand(prevState => {
            let updatedBrandData = [...prevState];

            let BrandIndex = updatedBrandData.findIndex(cat => cat.brandId === brandID);

            if (BrandIndex === -1) {
                updatedBrandData.push({
                    brandName: brandname,
                    brandId: null,
                    ischecked: false,
                    subBrand: []
                });
                BrandIndex = updatedBrandData.length - 1;
            }

            let updatedBrand = { ...updatedBrandData[BrandIndex] };

            let updatedSubBrand = updatedBrand.subBrand.filter(sub => sub.subBrandId !== subBrandID);

            if (isChecked) {
                updatedSubBrand.push({
                    subBrandname: subBrand,
                    subBrandId: subBrandID,
                    ischecked: true
                });
            }

            updatedBrand.subBrand = updatedSubBrand;
            updatedBrand.ischecked = updatedSubBrand.length > 0 ? true : true;

            updatedBrandData[BrandIndex] = updatedBrand;

            return updatedBrandData;
        });
    }

    const clearForm = () => {
        navigate("/product-page")
    }


    const checkSkuValueHandler = async (permaSkuValue) => {
        setLoading(true)
        try {
            const response = await MasterServices.checkSkuValue(permaSkuValue);
            console.log("checkSkuValueHandler", response)

            if (response?.data?.data?.length > 0 && response?.data?.message === "SKU Exists") {
                toast.error(response?.data?.message)
                setLoading(false)
                setDisableSku((prev => ({
                    ...prev,
                    checked: true
                })))

            } else {
                setLoading(false)
                setDisableSku((prev => ({
                    ...prev,
                    checked: false
                })))
            }
        }
        catch (error) {
            console.log("checkSkuValueHandler", error)
        }
    }
    const transformString = (str) => {
        return str
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '');
    }

    const transformSku = (str) => {
        return str.toUpperCase().replace(/\s+/g, '-').replace(/[^A-Z0-9-]/g, '');
    }

    useEffect(() => {
        // if (selectedBrand) {
        //     setPermaLink((prev) => ({
        //         ...prev,
        //         designer: selectedBrand?.brandname ? transformString(selectedBrand?.brandname) : ""
        //     }))
        // }

        if (selectedBrand?.length > 0) {
            selectedBrand.forEach((ele, index) => {
                if (index === 0) {
                    setPermaLink(prev => ({
                        ...prev,
                        designer: ele?.brandName ? transformString(ele?.brandName) : '',
                    }))
                }
            })
        }
        if (selectedCategories?.length > 0) {
            selectedCategories.forEach((ele, index) => {
                if (index === 0) {
                    setPermaLink(prev => ({
                        ...prev,
                        category: ele?.catname ? transformString(ele?.catname) : '',
                    }))
                }
            })
        }
        if (selectedCategories?.length <= 0) {
            setPermaLink(prev => ({
                ...prev,
                category: '',
            }))
        }
        if (selectedBrand?.length <= 0) {
            setPermaLink(prev => ({
                ...prev,
                designer: '',
            }))
        }

    }, [selectedCategories, selectedBrand])

    useEffect(() => {
        const parts = [];
        if (permaLink.category) {
            parts.push(transformString(permaLink.category));
        }
        if (permaLink.designer) {
            parts.push(transformString(permaLink.designer));
        }
        if ((permaLink.shortDescription && (permaLink.skuValue && disableSku.checked === false))) {
            parts.push(`${transformString(permaLink.shortDescription)}-${transformSku(permaLink.skuValue)}`);
        } else if (permaLink.skuValue && disableSku.checked === false) {

            parts.push(transformSku(permaLink.skuValue));

        } else if (permaLink.shortDescription) {
            parts.push(transformString(permaLink.shortDescription));
        }


        const newUrl = `${defaultUrl}${parts.join('/')}`;
        setFullUrl(newUrl);


    }, [permaLink, disableSku]);

    const fetchData = useCallback(debounce(async () => {
        if (permaLink.skuValue) {
            const permaSkuValue = transformSku(permaLink.skuValue);
            if (permaSkuValue) {

                await checkSkuValueHandler(permaSkuValue);
            }
        }
    }, 1000), [permaLink.skuValue]);

    useEffect(() => {
        fetchData();
        return () => {
            fetchData.cancel();
        };
    }, [permaLink.skuValue, fetchData]);


    const editFormatDateTime = (date) => {


        if (!date) return '';
        const dates = new Date(date);
        const year = dates.getUTCFullYear();
        const month = String(dates.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(dates.getUTCDate()).padStart(2, '0');
        const hours = String(dates.getUTCHours()).padStart(2, '0');
        const minutes = String(dates.getUTCMinutes()).padStart(2, '0');
        const customDate = `${year}-${month}-${day}T${hours}:${minutes}`;
        if (customDate) {
            setScheduleDateTime(customDate)
        }
        //return `${year}-${month}-${day}T${hours}:${minutes}`;
       
    }
    const formatDateTime = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');

        const customDate = `${year}-${month}-${day}T${hours}:${minutes}`
        if (customDate) {
            setScheduleDateTime(customDate)
        }

        //console.log("formatDateTime", `${year}-${month}-${day}T${hours}:${minutes}`)
        //return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    console.log("attributesOption",attributesOption)

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{product_id ? (viewFlag ? "View Product" : "Edit Product") : "Add Product"}</h1>
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                    //submitForm(e)
                }}
                className="py-5">
                <div className="bg-white rounded-lg  shadow-sm border-2 border-gray-200" >
                    <div className="p-5 border-b-[1px] border-[#ebac78]" >
                        <h1 className="text-md font-semibold text-black">Product Information</h1>
                    </div>
                    {/* product name,permalink desc */}
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white p-5 rounded-b-lg py-10">
                        <div className="md:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Product Name
                            </label>
                            <div className="">
                                <input
                                    value={values.p_product_name}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    disabled={viewFlag ? true : false}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_product_name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm   placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Product Name"
                                />

                            </div>
                            {errors.p_product_name && touched.p_product_name ? (
                                <p className="text-red-600 text-sm">
                                    {errors.p_product_name}
                                </p>
                            ) : null}
                        </div>
                        <div className="md:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Short Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.p_product_short_desc}
                                    disabled={viewFlag ? true : false}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setPermaLink((prev) => ({
                                            ...prev,
                                            shortDescription: transformString(event?.target?.value)
                                        }))
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_product_short_desc"
                                    className="block w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Short Description"
                                />

                            </div>
                            {errors.p_product_short_desc && touched.p_product_short_desc ? (
                                <p className="text-red-600 text-sm">
                                    {errors.p_product_short_desc}
                                </p>
                            ) : null}
                        </div>
                        <div className="md:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Sku value
                            </label>
                            <div className="">
                                <input
                                    value={values.p_inv_sku}
                                    disabled={viewFlag ? true : false}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setPermaLink((prev) => ({
                                            ...prev,
                                            skuValue: event.target.value
                                        }))
                                        if (!event.target.value) {
                                            setDisableSku((prev) => ({
                                                ...prev,
                                                checked: false
                                            }))
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_inv_sku"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm   placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="SKU Value"
                                />
                                {
                                    disableSku.checked === true &&
                                    (
                                        <p className="text-sm text-red-500">{disableSku.errorName}</p>
                                    )

                                }

                            </div>

                        </div>
                        <div className="md:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Permalink
                            </label>
                            <div className="flex items-center  rounded-md border-0  text-gray-900 shadow-sm w-full ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">
                                <label className="px-2 py-2  text-black rounded-md  text-sm bg-slate-100 w-full border-2">{fullUrl}</label>
                                {/* <input type="text"
                                value={values.parmalink}
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                onBlur={handleBlur}
                                name="parmalink"
                                className="p-2 flex-1 overflow-hidden border-0 rounded-r-md sm:text-sm placeholder:text-gray-400  ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78] " 
                            /> */}
                            </div>
                        </div>

                        <div className="md:col-span-2">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Long Description
                            </label>
                            <div className="">
                                <textarea
                                    value={values.p_product_desc}
                                    disabled={viewFlag ? true : false}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_product_desc"
                                    className="block w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Long Description"
                                />

                            </div>
                        </div>



                        <div className="md:col-span-1">
                            <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                Ships Within
                            </label>
                            <div className="">
                                <input
                                    value={values.ship_within}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    // onBlur={handleBlur}
                                    type="text"
                                    name="ship_within"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Ships within"
                                />

                            </div>
                        </div>


                    </div>
                </div>


                {/* {attributes & gallery,video, images category} */}
                <div className="flex flex-col md:flex-row gap-4 my-5 ">
                    <div className="md:basis-3/4">
                        {/* attributes */}
                        <div className="">
                            <Attributes

                                selectProductOption={selectProductOption}
                                setSelectProductOption={setSelectProductOption}
                                ProductSelectOption={ProductSelectOption}
                                values={values}
                                handleChange={handleChange}
                                setVariationCheck={setVariationCheck}
                                variationCheck={variationCheck}
                                setAttributeData={setAttributeData}
                                attributeData={attributeData}
                                error={error}
                                attributesOption={attributesOption}
                                setParentAttribute={setParentAttribute}
                                parentAttribute={parentAttribute}
                                selectChildValue={selectChildValue}
                                setSelectChildValue={setSelectChildValue}
                                setChildInputValue={setChildInputValue}
                                childInputValue={childInputValue}
                                setLoopVariation={setLoopVariation}
                                loopVariation={loopVariation}
                                setVariationForm={setVariationForm}
                                variationForm={variationForm}
                                setLoading={setLoading}
                                editProduct={editProduct}
                                flagParentAttribute={flagParentAttribute}
                                viewFlag={viewFlag}

                            />
                        </div>
                        {/* product gallery */}
                        <div className="bg-white rounded-lg my-5 border-2 border-gray-200">
                            <div className="">
                                <h4 className="text-md text-black font-semibold p-4">Product Gallery</h4>
                                <div className="border-[1px] border-t-[#ebac78]  h-[300px] overflow-auto">
                                    <div className="flex items-center  p-4 justify-center h-full ">
                                        {
                                            multipleImages?.length > 0 ?
                                                <div className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200  text-white p-5 overflow-auto">
                                                    <div className="flex flex-wrap gap-4">
                                                        {
                                                            multipleImages.map((img, index) => {
                                                                // const imageUrl = URL.createObjectURL(ele);
                                                                return (
                                                                    <>

                                                                        <div key={index} className="relative">
                                                                            <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                                                <img src={`${img.includes("https") ? img : IMAGEURL + img}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-25" alt="name" />
                                                                                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                                                    <button
                                                                                        className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                                        disabled={viewFlag ? true : false}
                                                                                        onClick={(e) => { e.preventDefault(); handleRemoveImage(index); }}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                        <label className="border border-black w-[150px] h-[200px] rounded-lg flex justify-center items-center text-[#a96a37] cursor-pointer" >
                                                            <span className="text-md text-[#a96a37]">Add More</span>
                                                            <input type="file"
                                                                accept="image/*"
                                                                disabled={viewFlag ? true : false}
                                                                multiple onChange={(e) => handleMultipleImagesUpload(e, setMultipleImages)}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div className="flex items-center p-4 justify-center h-full w-full">

                                                    <label className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">

                                                        <div>
                                                            <span className="text-md text-gray-500">* Upload Images</span>
                                                            {/* <input type="file" onChange={(e) => handleMultipleImag(e)} className="hidden" /> */}
                                                            <input type="file"
                                                                accept="image/*"
                                                                multiple onChange={(e) => handleMultipleImagesUpload(e, setMultipleImages)}
                                                                className="hidden"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* product video */}
                        <div className="bg-white rounded-lg border-2 border-gray-200">
                            <div className="">
                                <h4 className="text-md text-black font-semibold p-4">Product Video</h4>
                                <div className="border-[1px] border-t-[#ebac78] w-full  h-[300px]">
                                    {
                                        video ?
                                            <div className="relative flex items-center p-4 justify-center w-full">
                                                <div className="border border-black w-full h-[200px] rounded-lg overflow-hidden relative">
                                                    <video controls className="absolute top-0 left-0 w-full h-full object-cover ">
                                                        <source src={`${VIDEOURL}${video}`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>

                                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center  pointer-events-none">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg pointer-events-auto"
                                                            onClick={() => setVideo(null)}
                                                            disabled={viewFlag ? true : false}

                                                        >
                                                            Remove
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div className="flex items-center p-4 justify-center h-full w-full">
                                                {
                                                    parseInt(progress) > 0 && progress < 100 ?

                                                        <div className="w-full flex justify-center items-center">
                                                            {/* <ProgressBar progress={progress} /> */}
                                                            <Spinner />
                                                        </div>
                                                        :
                                                        <label for="fileInput" className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                            <span className="text-md text-gray-500">* Upload Video</span>
                                                            <input type="file" id="fileInput" accept="video/*" disabled={viewFlag ? true : false} className="hidden" onChange={(e) => handleVideoUpload(e, setVideo)} />
                                                        </label>
                                                }

                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* product pricing,meta */}
                        <div className="bg-white rounded-lg border-2 border-gray-200 shadow-sm mt-5">
                            <div className=" p-5 border-b-[1px] border-[#ebac78] ">
                                <h1 className="text-md font-semibold text-black">Meta Information</h1>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5 bg-white rounded-b-lg p-5  items-start">

                                <div className="">
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Meta title
                                    </label>
                                    <input
                                        value={values.p_meta_title}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        disabled={viewFlag ? true : false}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="p_meta_title"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder=" Enter Meta title"
                                    />
                                </div>
                                <div className="">
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Meta Tags
                                    </label>
                                    <input
                                        value={values.p_meta_tags}
                                        disabled={viewFlag ? true : false}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="p_meta_tags"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder=" Enter Meta Tags"
                                    />
                                </div>
                                <div className="lg:col-span-2 xl:col-span-3">
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Meta description
                                    </label>
                                    <textarea
                                        value={values.p_meta_desc}
                                        disabled={viewFlag ? true : false}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="p_meta_desc"
                                        className="block  w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder="Short Description"
                                    />

                                </div>
                                <div className="lg:col-span-2 xl:col-span-3 w-full">
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Meta Image
                                    </label>

                                    <div className=" ">
                                        {
                                            metaImage ?
                                                <div className="flex items-center justify-center w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200">
                                                    <div className="relative">
                                                        <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                            <img src={`${IMAGEURL}${metaImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                                <button
                                                                    className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                    disabled={viewFlag ? true : false}
                                                                    onClick={() => SetMetaIMage(null)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="flex items-center justify-center w-full">
                                                    {
                                                        parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                            <div className="w-full h-60 flex justify-center items-center">
                                                                <Spinner />
                                                            </div>
                                                            :
                                                            <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                                <span className="text-md text-gray-500">* Upload Image</span>
                                                                <input type="file" class="hidden" disabled={viewFlag ? true : false} accept="image/*" onChange={(e) => handleMetaImageUpload(e, SetMetaIMage)} />

                                                            </label>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Status
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={statusOption}
                                            isDisabled={viewFlag ? true : false}
                                            onChange={(selectedOption) => {
                                                setStatusOption(selectedOption)
                                                if (selectedOption) {
                                                    setError("")
                                                }
                                            }}

                                            className="basic-single sm:text-sm "
                                            options={statusDataOption}
                                            styles={customStyles}
                                            isClearable
                                        />

                                    </div>
                                    {error?.statusError && <p className="text-red-500 text-sm ">{error?.statusError}</p>}
                                </div>
                                {
                                    statusOption?.label === "Schedule" && (
                                        <div>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                Schedule
                                            </label>
                                            <div className="flex items-end">
                                            
                                                <DateTimePicker
                                                    value={scheduldateTime}
                                                    onChange={(newDate) => {
                                                        //console.log("Selecteddate:", newDate);
                                                        formatDateTime(newDate)
                                                        setFieldValue('p_product_schedule_date', newDate); // Update Formik or state value
                                                    }}
                                                    format="y-MM-dd HH:mm" // 24-hour format
                                                    className=""
                                                />

                                            </div>
                                        </div>)
                                }

                            </div>
                        </div>
                    </div>
                    <div className="md:basis-1/4">
                        <div className="flex flex-col gap-5">
                            <div className="bg-white rounded-xl border-2 border-gray-200">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Categories</h4>
                                    <div className="border-[1px] border-t-[#ebac78] overflow-y-auto h-[300px]">
                                        <div className="flex px-5 py-2" >
                                            <input type="text"
                                                value={searchCategory}
                                                onChange={(e) => setSearchCategory(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                                placeholder="Search Category" />
                                        </div>
                                        <div className="p-5 flex flex-col gap-1 ">
                                            {
                                                categories?.length > 0 && categories.filter((ele) => ele?.p_category_name.toLowerCase().includes(searchCategory.toLowerCase())).filter(pp => pp?.is_category_active === "Y").map((ele, index) => {
                                                    return (

                                                        <div className="" key={index}>
                                                            <div className="flex gap-3 items-center px-5">
                                                                <input type="checkbox"

                                                                    //checked={!!selectedCategories[ele.p_category_name]?.isChecked}
                                                                    disabled={viewFlag ? true : false}
                                                                    checked={selectedCategories?.length > 0 && selectedCategories.find(cat => cat.catname === ele.p_category_name)?.ischecked}
                                                                    //checked={editProduct[0]?.p_category_id && editProduct[0]?.p_category_id.split(';').includes(ele?.p_category_name) || false}
                                                                    onChange={(e) =>
                                                                        handleCategoryChange(ele?.p_category_name, e.target.checked, ele?.category_id)
                                                                    }
                                                                    className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                <h5 className="text-sm text-gray-500">{ele?.p_category_name}</h5>
                                                            </div>
                                                            {
                                                                ele?.sub_category?.length > 0 && ele?.sub_category?.filter(pp => pp?.is_active === "Y").map((sb, ind) => {
                                                                    return (
                                                                        <div className="flex ml-8" key={ind}>
                                                                            <div className="flex gap-3 items-center px-5 ">
                                                                                <input type="checkbox"
                                                                                    disabled={viewFlag ? true : false}
                                                                                    checked={
                                                                                        selectedCategories.find(cat => cat.catname === ele.p_category_name)?.subcat.find(sub => sub.subcatname === sb.cat_name)?.ischecked
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleSubCategoryChange(
                                                                                            ele?.p_category_name,
                                                                                            ele?.category_id,
                                                                                            sb?.cat_name,
                                                                                            e.target.checked,
                                                                                            sb?.id
                                                                                        )
                                                                                    }
                                                                                    className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                                <h5 className="text-sm text-gray-500">{sb?.cat_name}</h5>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl border-2 border-gray-200">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Brands</h4>
                                    <div className="border-[1px] border-t-[#ebac78] overflow-y-auto h-[300px]">
                                        <div className="flex px-5 py-2" >
                                            <input type="text"
                                                value={searchBrand}
                                                onChange={(e) => setSearchBrand(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]" placeholder="Search brand" />
                                        </div>
                                        <div className="p-5 flex flex-col gap-1 ">
                                            {
                                                brandData
                                                    .filter((ele) => ele?.m_name.toLowerCase().includes(searchBrand.toLowerCase()))
                                                    .filter(pp => pp?.m_designer_active === "Y")
                                                    .sort((a, b) => a.m_name.localeCompare(b.m_name))
                                                    .map((ele, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className="flex gap-3 items-center px-5">
                                                                    <input type="checkbox"
                                                                        disabled={viewFlag ? true : false}
                                                                        checked={selectedBrand?.length > 0 && selectedBrand.find(bnd => bnd.brandId === ele.m_designers_id)?.ischecked}
                                                                        onChange={(e) => handleBrandChange(ele?.m_name, e.target.checked, ele?.m_designers_id)}
                                                                        className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                    <h5 className="text-sm text-gray-500">{ele?.m_name}</h5>
                                                                </div>
                                                                {/* {
                                                                    ele?.subdesigners?.length > 0 && ele?.subdesigners?.map((sb, ind) => {
                                                                        return (
                                                                            <div className="flex ml-8" key={ind}>
                                                                                <div className="flex gap-3 items-center px-5 ">
                                                                                    <input type="checkbox"
                                                                                        disabled={viewFlag ? true : false}
                                                                                        checked={
                                                                                            selectedBrand.find(cat => cat.brandId === ele.m_designers_id)?.subBrand.find(sub => sub.subBrandId === sb.m_sub_designers_id)?.ischecked
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleSubBrandChange(
                                                                                                ele?.m_name,
                                                                                                ele?.m_designers_id,
                                                                                                sb?.m_name,
                                                                                                e.target.checked,
                                                                                                sb?.m_sub_designers_id
                                                                                            )
                                                                                        }
                                                                                        className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                                    <h5 className="text-sm text-gray-500">{sb?.m_name}</h5>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                } */}

                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl border-2 border-gray-200">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Image</h4>
                                    <div className="border-[1px] border-t-[#ebac78]  h-[300px]">
                                        <div className="flex items-center p-4 justify-center">
                                            {
                                                singleImage ?
                                                    <div className="relative">
                                                        <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                            <img src={`${singleImage.includes("https") ? singleImage : IMAGEURL + singleImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                                <button
                                                                    className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                    onClick={() => setSingleImage(null)}
                                                                    disabled={viewFlag ? true : false}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className="flex items-center p-4 justify-center h-full w-full">
                                                        {
                                                            parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                                <div className="w-full flex justify-center items-center">
                                                                    <Spinner />
                                                                </div>
                                                                :

                                                                <label className="w-60 h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                                    <span className="text-md text-gray-500">* Front Image</span>

                                                                    <input type="file" class="hidden" accept="image/*" onChange={(e) => handleSingleImageUpload(e, setSingleImage)} />

                                                                </label>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="h-px my-5 bg-gray-300 border-0 "></hr>

                {/* submit.cancel button */}
                <div className="flex gap-5 justify-start ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button

                        disabled={viewFlag ? true : false}
                        onClick={() => { handleSubmit() }}
                        type="button"
                        className={`rounded-md border text-[#fff] shadow-sm px-4 py-2 text-sm font-medium ${viewFlag ? "bg-gray-400 cursor-not-allowed opacity-90" : "  bg-[#3a4b43] cursor-pointer"} `}


                    >
                        {submitLoader ?
                            <RotatingLineSpinner /> : "Submit"
                        }
                    </button>
                </div>

            </form>


            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }

        </>
    )
}

export default AddProduct