import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { product_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Spinner, { RotatingLineSpinner } from "../../../components/Spinner";

import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";
import { ArrowUpOnSquareIcon, XMarkIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { PlayCircleIcon, PlusCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { CustomContext } from "../../../context/MyContext";


const Product = () => {
    const pages = [{ title: "Product", href: "/product-page", module_id: 1 }];
    const [productData, setProductData] = useState([]);
    const [filterProductData, setFilterProductData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [skuOption, setSkuOption] = useState([]);
    const [selectSku, setSelectSku] = useState(null);
    const [selectStatus, setSelectStatus] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [fileUpload, setFileUpload] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const { rolePermissionData } = useContext(CustomContext);
    const [checkPermission, setCheckPermission] = useState([])
    const navigate = useNavigate();
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    useEffect(() => {
        const fetchData = async () => {
            await getProducts()
        }

        fetchData()
    }, [])

    const statusOption = [
        {
            value: "1",
            label: "Publish"
        },
        {
            value: "2",
            label: "Draft"
        },
        {
            value: "3",
            label: "Schedule"
        },
    ]

    //console.log("rolePermissionData",rolePermissionData)

    const getProducts = async () => {
        try {

            const response = await MasterServices.getAllProduct();
            // console.log("getProducts", response)
            if (response?.data?.data?.length > 0) {
                // console.log("getProducts",response?.data?.data)
                setProductData(response?.data?.data);
                setFilterProductData(response?.data?.data)
                setLoading(false);
                const skuFilter = response?.data?.data?.map((ele) => ({
                    label: ele?.p_product_sku,
                    value: ele?.p_product_sku
                }))
                setSkuOption(skuFilter)
            }
        } catch (error) {
            toast.error("error in getProducts")
        }
    }

    useEffect(() => {
        console.log("filterProductData", filterProductData)
        // console.log("selectStatus",{
        //     selectStatus
        // })
        if (dateInput?.startDate || dateInput?.endDate || selectSku || selectStatus) {

            const editFormatDateTime = (date) => {


                if (!date) return '';
                const dates = new Date(date);
                const year = dates.getUTCFullYear();
                const month = String(dates.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(dates.getUTCDate()).padStart(2, '0');
                const hours = String(dates.getUTCHours()).padStart(2, '0');
                const minutes = String(dates.getUTCMinutes()).padStart(2, '0');
                const customDate = `${year}-${month}-${day}T${hours}:${minutes}`;

                return `${year}-${month}-${day}T${hours}:${minutes}`;

            }

            const filterData = filterProductData.filter((ele) => {
                const dateFilter = moment(ele?.u_product_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                const statusFlag = selectStatus?.label;
                const skuName = selectSku?.value;

                const scheduleDates = editFormatDateTime(ele.p_product_schedule_dates);
                const createdDate = editFormatDateTime(ele?.u_product_created_at);
                const activeStatus = ele?.p_porducts_status;
                const scheduleDatesCustom = scheduleDates && scheduleDates !== "null" && scheduleDates !== null ? scheduleDates : createdDate;
                const currentDate = new Date();

                let status = activeStatus;

                if (scheduleDatesCustom) {
                    const scheduleDate = new Date(scheduleDatesCustom);

                    if (scheduleDate > currentDate) {
                        status = "Schedule";
                    } else if (scheduleDate < currentDate) {
                        status = activeStatus === "Draft" ? "Draft" : "Publish";
                    } else {
                        status = "Draft";
                    }
                }

                const skuMatches = skuName ? ele?.p_product_sku === skuName : true;

                const statusMatches = statusFlag ? status?.toLowerCase() === statusFlag?.toLowerCase() : true;

                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);

                return skuMatches && statusMatches && dateMatches;
            })
            // console.log("filterData", filterData)
            if (filterData?.length > 0) {
                setProductData(filterData)
            } else {
                setProductData([])
            }
        }

    }, [selectSku, filterProductData, selectStatus, dateInput])


    useEffect(() => {
        if (rolePermissionData?.length > 0) {
            console.log("rolePermissionData", rolePermissionData);
            const filterData = rolePermissionData?.filter((ele) => ele?.module_name === "Products");
            console.log("filterData", filterData);
            setCheckPermission(filterData)
        }

    }, [rolePermissionData]);



    const clearForm = () => {

        setSelectStatus(null)
        setDateInput({
            startDate: "",
            endDate: ""
        })
        setSelectSku(null)
        setProductData(filterProductData)
    }

    const downloadExcelBtn = () => {

        // console.log("userData",userData)

        const filterData = productData?.map((ele) => {
            return {
                Id: ele?.p_porduct_id,
                ProductName: ele?.p_product_name,
                ShortDescription: ele?.p_product_short_desc,
                SKU: ele?.p_product_sku,
                Price: ele?.p_product_price,
                CreatedAt: moment(ele?.u_product_created_at).format("YYYY-MM-DD")
            }
        })

        downloadSheet(filterData, "product")

        console.log("filterData", filterData)
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setFileUpload(file);
        } else {
            setFileUpload('No file chosen');
        }
    };

    const handleFileImport = async () => {
        if (!fileUpload) {
            toast.info("Please select file");
            return
        }

        console.log("formData", fileUpload)
        const formData = new FormData();
        formData.append("image", fileUpload);
        try {
            setSubmitLoader(true)
            const response = await MasterServices.importProductApi(formData);
            console.log("handleFileImport", response)
            if (response?.data?.data?.length > 0) {
                setSubmitLoader(false)
                toast.success("Product Import Successfully");
                setOpenDrawer(false)
                window.location.reload()
            }
            if (response?.data?.data?.length === 0) {
                toast.error(response?.data?.message);

                setSubmitLoader(false)
                setOpenDrawer(false)
                setFileUpload(null)

            }
            else {
                setSubmitLoader(false)
            }
        }
        catch (error) {

            setSubmitLoader(false)
            console.log("handleFileImport", error);
            setOpenDrawer(false)
        }
    }


    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Product List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the products.</p>
                </div>
                {
                    checkPermission[0]?.is_add === "Y" && (
                        <Link

                            to="/add-product"
                            className=" rounded-md flex items-center uppercase px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                        >
                            <label>add product</label>
                            <PlusCircleIcon className="w-6 h-6" />
                        </Link>
                    )}
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Product
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectSku}
                                options={skuOption}
                                onChange={(selectedOption) => {
                                    setSelectSku(selectedOption)
                                }}
                                name="selectSku"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"

                                placeholder="Select"
                                value={selectStatus}

                                options={statusOption}
                                onChange={(selectedOption) => {
                                    setSelectStatus(selectedOption)
                                }}
                                name="selectStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}

                            />

                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className="  mt-4 flex flex-col sm:flex-row  w-full justify-end  gap-3">
                {/* {
                    checkPermission[0]?.is_add === "Y" && (
                        <button onClick={() => setOpenDrawer(true)} type="button" className="flex gap-x-2 cursor-pointer w-fit items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold   text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white">
                            <label>Import Product</label>
                            <ArrowUpOnSquareIcon className="w-6 h-6 " />
                        </button>
                    )
                } */}
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="flex gap-x-2 w-fit items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold   text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >

                    <label>Download Excel</label>
                    <ArrowDownTrayIcon className="w-6 h-6 " />
                </button>
            </div>

            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={product_columns({ checkPermission })}
                        data={productData}
                    />
            }

            <Dialog open={openDrawer} onClose={() => {
                setOpenDrawer(false)
                setFileUpload(null)
            }} className="relative z-50">
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-sm transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className="flex h-full flex-col overflow-y-scroll bg-white  shadow-xl">
                                    <div className="px-4 sm:px-6 bg-[#3a4b43] py-5">
                                        <div className="flex items-start justify-between ">
                                            <DialogTitle className="text-base font-semibold text-white">Import Product</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOpenDrawer(false)
                                                        setFileUpload(null)
                                                    }}
                                                    className="relative rounded-full bg-white text-gray-500 hover:text-gray-500 "
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex flex-col gap-4">
                                            {/* File Input Section */}
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 border-gray-300">
                                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                        <ArrowUpOnSquareIcon className="w-8 h-8 mb-3 text-gray-500" />
                                                        <p className="mb-2 text-sm text-gray-500">
                                                            Click to upload file
                                                        </p>

                                                    </div>
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                            </div>


                                            {fileUpload && (
                                                <div className="text-sm text-gray-600 bg-gray-50 p-3 rounded-lg">
                                                    Selected file: {fileUpload.name}
                                                </div>
                                            )}

                                            {/* Export Button */}
                                            <button
                                                onClick={handleFileImport}
                                                disabled={!fileUpload}
                                                className="px-4 py-2 bg-green-300 text-green-900 rounded-lg  disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed transition-colors duration-200 font-medium"
                                            >
                                                {submitLoader ?
                                                    <RotatingLineSpinner /> : "Product Import"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Product