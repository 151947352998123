import React, { useEffect, useState, useContext } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import Select from "react-select";
import { Link } from "react-router-dom";
import { customStyles } from "../../../helper/customStyles";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/tables/table";
import { designerSection_columns, imageSection_columns, reditSection_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { CustomContext } from "../../../context/MyContext";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";

const ReEditSection = () => {
    const pages = [{ title: "ReEditSection", href: "/reeditsection", module_id: 1 }];
    const [loading, setLoading] = useState(true);
    const [checkPermission, setCheckPermission] = useState([]);
    const [selectStatus, setSelectStatus] = useState(null)
    const { rolePermissionData } = useContext(CustomContext);
    const [reditData, setReditData] = useState([]);
    const [filterReditData, setFilterReditData] = useState([])
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })

    const reditSectionDemo = [
        {
            "h_designers_section_id": 1,

            "h_designers_section_heading": "dmeo",
            "h_designers_section_order": 1,

            "h_designers_redirect_link": "dfdsf",
            "h_designers_in_section_active": "N",
            "h_iamge_url": "https"

        }
    ]

    const statusOption = [
        {
            label: "Active",
            value: "Y"
        },
        {
            label: "Inactive",
            value: "N"
        },
    ]


    useEffect(() => {
        const fetchData = async () => {
            await getReditSectionList()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (rolePermissionData?.length > 0) {
            // console.log("rolePermissionData", rolePermissionData);
            const filterData = rolePermissionData?.filter((ele) => ele?.module_name === "ReEditSection");
            // console.log("filterData", filterData);
            setCheckPermission(filterData)

        }

    }, [rolePermissionData]);

    useEffect(() => {
        if (dateInput?.startDate || dateInput.endDate || selectStatus) {

            const filterData = filterReditData.filter((ele) => {
                const dateFilter = moment(ele?.h_re_edit_section_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput?.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput?.endDate).format("YYYY-MM-DD") : null;
                const statusFlag = selectStatus?.value ? selectStatus?.value : null

                const statusMatches = statusFlag ? ele?.h_re_edit_section_active === statusFlag : true
                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);


                return statusMatches && dateMatches

            })

            if (filterData?.length > 0) {
                setReditData(filterData)
            }
            else {
                setReditData([])
            }
        }
    }, [dateInput, selectStatus]);

    const getReditSectionList = async () => {
        try {
            const response = await MasterServices.getAllHomePageDataApi();
            console.log("getReditSectionList", response?.data?.data?.ReEditSection)
            if (response?.data?.data?.ImageSection?.length > 0) {
                setReditData(response?.data?.data?.ReEditSection);
                setFilterReditData(response?.data?.data?.ReEditSection)
                setLoading(false)
            } else {
                setReditData([])
                setLoading(false)
            }
        }
        catch (error) {
            console.log("getReditSectionList", error)
        }
    }

    const downloadExcelBtn = () => {
        const filterData = reditData?.map((ele) => {
            return {
                Id: ele?.h_re_edit_section_id,
                RedirectLink: ele?.h_re_edit_section_redirect_link,
                Status: ele?.h_re_edit_section_active,
                Order: ele?.h_re_edit_section_order,
                ImageLink: ele?.h_iamge_url,
                CreatedAt: moment(ele?.h_re_edit_section_created_at).format("YYYY-MM-DD")
            }
        })

        downloadSheet(filterData, "reditsection")

        //console.log("filterData",filterData)
    }

    const clearForm = () => {
        setDateInput({
            startDate: "",
            endDate: ""
        });
        setSelectStatus(null)
        setReditData(filterReditData);

    }

    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Re-Edit List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the ReEditImage.</p>
                </div>
                {
                    checkPermission[0]?.is_add === "Y" && (
                        <Link

                            to="/add-reditsection"
                            className=" rounded-md uppercase px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                        >
                            ADD image
                        </Link>
                    )
                }

            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                placeholder="Select"
                                value={selectStatus}
                                // onBlur={handleBlur}
                                onChange={(selectedOption) => {
                                    setSelectStatus(selectedOption)
                                }}
                                name="userStatus"
                                options={statusOption}
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>

                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                value={dateInput.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}

                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>

            <div className=" w-full mt-4 flex justify-end">
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={reditSection_columns({ checkPermission })}
                        data={reditData}
                    />
            }

        </>
    )

}

export default ReEditSection