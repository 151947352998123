import React, { useContext, useEffect,useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import DashboardLayout from "../layout/dashboardLayout";
import { CustomContext } from "../context/MyContext";

const ProtectedRoute = ({ moduleName, mandatoryPermission =null }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userRoleId = user?.u_user_role_id;
  // const { rolePermissionData } = useContext(CustomContext);
  const [hasAccess, setHasAccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const storedRolePermissionData = localStorage.getItem("rolePermissionData");
  const rolePermissionData = storedRolePermissionData ? JSON.parse(storedRolePermissionData) : [];
 // console.log("rolePermissionData",rolePermissionData)
  useEffect(() => {
    if (rolePermissionData?.length > 0) {
   // console.log("rolePermissionData",rolePermissionData)
      const modulePermission = rolePermissionData.find(
        (permission) =>
          permission.user_role_id === parseInt(userRoleId) &&
          permission.module_name === moduleName
      );
     // console.log("modulePermission",modulePermission)
      const canView = modulePermission?.is_view === "Y";
      const canAdd = modulePermission?.is_add === "Y"
      const canEdit= modulePermission?.is_edit === "Y"
      //console.log("canView",canView)
      if(mandatoryPermission && mandatoryPermission === "is_add"){
        setHasAccess(canAdd)
      }
      else if(mandatoryPermission && mandatoryPermission === "is_edit"){
        setHasAccess(canEdit)
      }
      else{
        setHasAccess(canView); 
      }
     
      
    }
  }, [rolePermissionData, userRoleId, moduleName]);
 
  if (!user) {
    
    return <Navigate to="/login" />;
  }

  

  if (hasAccess === false) {
    setTimeout(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("rolePermissionData");
      window.location.href = "/login";
    }, 1000);
    return <Navigate to="/login" />;
  }

  // return user ? (
  //   <DashboardLayout>
  //     <Outlet />
  //   </DashboardLayout>
  // ) : (
  //   <Navigate to="/login" />
  // );

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default ProtectedRoute;
