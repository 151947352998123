import UploadFileComp from "../components/UploadFileComp";
const handleFileChange = async (e, setImageState,wdth,hght,setFieldValue,setSingleImageProgress) => {
    const filetype = e.target.files[0];
    console.log("handleFileChange", filetype)
    if (filetype) {
        //e.target.value = ''; // Clear the file input

        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = async () => {
                const { width, height } = img;
                console.log("width", width, "height", height)
                try {

                    if (width === wdth && height === hght) {
                        console.log("Image is valid.");
                        await UploadFileComp(filetype, setImageState, setSingleImageProgress);
                    } else {
                        console.error(`Invalid image dimensions. Please upload an image with ${wdth}*${hght} px`);

                        setFieldValue("h_iamge_url", "");
                        alert(`Invalid image dimensions. Please upload an image with  ${wdth}*${hght} px.`);
                    }
                } catch (error) {
                    console.error("Error during upload:", error)
                    setFieldValue("h_iamge_url", "");
                    alert("An error occurred while uploading the file. Please try again.");
                }
            };

            img.onerror = () => {
                console.error("Error loading image.");
                alert("Error loading the image. Please try again.");
            };
        };

        reader.readAsDataURL(filetype);
    }

    e.target.value = '';
}


export default handleFileChange