import './App.css';
import RoutesConfig from './routes/Routes';
import { toast, ToastContainer } from "react-toast";
function App() {
  return (
    <div className='relative'>
     <RoutesConfig />
     <ToastContainer delay={5000} position="top-right" className="toastContainer" />
     </div>
   
  );
}

export default App;
