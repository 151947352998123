import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Table from "../../../components/tables/table";
import { order_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { ToastContainer, toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import { customStyles } from "../../../helper/customStyles";
import moment from "moment";
import downloadSheet from "../../../helper/downlaodExcel";
import { CustomContext } from "../../../context/MyContext";

const Orders = () => {
    const pages = [{ title: "Orders", href: "/order-page", module_id: 1 }];
    const [orderData, setOrderData] = useState([])
    const [filterOrderData, setFilterOrderData] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectOrderStatus, setSelectOrderStatus] = useState(null);
    const [customerOption, setCustomerOption] = useState([]);
    const [selectCustomerName, setSelectCustomerName] = useState(null);
    const [skuOption, setSkuOption] = useState([]);
    const [selectSku, setSelectSku] = useState(null);
    const [orderModeSelect, setSelectOrderMode] = useState(null)
    const [orderStatusOption, setOrderStatusOption] = useState([]);
    const [customDiscount, setCustomDiscount] = useState(0);
    const { rolePermissionData } = useContext(CustomContext);
    const [checkPermission, setCheckPermission] = useState([]);
    const navigate = useNavigate()
    const [dateInput, setDateInput] = useState({
        startDate: "",
        endDate: ""
    })


    useEffect(() => {
        const fetchData = async () => {
            await getOrder();
            await getAllProduct();
            await getAllOrderStatus();
            await getUsers()
        }

        fetchData()
    }, [])

    const orderModeOption = [
        {
            label: "Direct",
            value: "1"
        },
        {
            label: "Admin",
            value: "2"
        },
    ]

    const getOrder = async () => {
        try {

            const response = await MasterServices.getAllOrder();

            if (response?.data?.data?.length > 0) {
                //console.log("getOrder", response?.data?.data)
                setOrderData(response?.data?.data);
                setFilterOrderData(response?.data?.data)
                setLoading(false);

                // const customerNameFilter = response?.data?.data.map((ele)=>({
                //     label:ele?.m_billing_name,
                //     value:ele?.m_billing_name
                // }))

                // setCustomerOption(customerNameFilter)
            }
        } catch (error) {
            toast.error("error in getOrder")
        }
    }

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        //console.log("response", response?.data?.data)

        if (response?.data?.data?.length > 0) {
            const optionData = []


            response?.data?.data.forEach((ele) => {


                optionData.push({
                    value: ele?.u_user_id,
                    fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
                    email: ele?.u_user_email
                })

            })
            console.log("optionData", optionData)
            setCustomerOption(optionData)
        } else {
            setCustomerOption([])
        }
    }

    const getAllOrderStatus = async () => {
        try {

            const response = await MasterServices.getOrderStatus();
            //console.log("response", response)
            if (response?.data?.data?.length > 0) {
                const filter = response?.data?.data.map(ele => ({
                    label: ele?.m_order_status_value,
                    value: ele?.m_order_status_id

                }))
                console.log("filter", filter)
                setOrderStatusOption(filter)
            }

        } catch (error) {
            toast.error(error)
        }
    }

    const getAllProduct = async () => {
        try {
            const response = await MasterServices.getAllProduct();

            if (response?.data?.data?.length > 0) {
                const filterData = response?.data?.data?.map((ele) => ({
                    label: ele?.p_product_sku,
                    value: ele?.p_porduct_id
                }))

                setSkuOption(filterData)
            }
        } catch (error) {
            setSkuOption([])
            console.log("getAllProduct", error)
        }
    }

    useEffect(() => {
        console.log("filterOrderData")
        if (dateInput?.startDate || dateInput?.endDate || selectOrderStatus || selectCustomerName || selectSku || orderModeSelect) {

            const filterData = filterOrderData?.filter((ele) => {
                const dateFilter = moment(ele?.m_order_created_at).format("YYYY-MM-DD");
                const startDate = dateInput?.startDate ? moment(dateInput.startDate).format("YYYY-MM-DD") : null;
                const endDate = dateInput?.endDate ? moment(dateInput.endDate).format("YYYY-MM-DD") : null;
                const statusFlag = selectOrderStatus?.label.toLowerCase();
                const filterStatusValue = ele?.m_order_status === "Initiated" ? "Pending Payment" : ele?.m_order_status?.includes("Placed") || ele?.m_order_status?.includes("Purchese") ? "Processing" : ele?.m_order_status
                const customer = selectCustomerName?.value;
                const skuName = selectSku?.value;
                const ordermode = orderModeSelect?.label.toLowerCase()
                const customerMatches = customer ? parseInt(ele?.u_user_id) === parseInt(customer) : true;
                const skuMatches = skuName ? parseInt(ele?.p_product_id) === skuName : true
                const orderStatusMatches = statusFlag ? filterStatusValue.toLowerCase() === statusFlag : true
                const dateMatches = (!startDate || startDate <= dateFilter) && (!endDate || endDate >= dateFilter);
                const orderMatches = ordermode ? ele?.m_order_mode && ele?.m_order_mode.toLowerCase() === ordermode : true

                return customerMatches && skuMatches && orderStatusMatches && dateMatches && orderMatches;
            })
            console.log("filterData >>>>>>>>>>>>>>>>>>> >>> >")
            if (filterData?.length > 0) {
                setOrderData(filterData)
            } else {
                setOrderData([])
            }
        }
    }, [dateInput, selectOrderStatus, selectCustomerName, selectSku, orderModeSelect]);

    useEffect(() => {
        if (rolePermissionData?.length > 0) {
            console.log("rolePermissionData", rolePermissionData);
            const filterData = rolePermissionData?.filter((ele) => ele?.module_name === "Orders");
            console.log("filterData", filterData);
            setCheckPermission(filterData)
        }

    }, [rolePermissionData]);

    const clearForm = () => {
        setDateInput({
            startDate: "",
            endDate: ""
        })
        setSelectOrderStatus(null)
        setSelectCustomerName(null)
        setSelectOrderMode(null)

        setOrderData(filterOrderData);
        setSelectSku(null)
    }

    const downloadExcelBtn = () => {

        const filterData = orderData?.map((ele) => {
            return {
                OrderId: ele?.m_order_id,
                UserId: ele?.u_user_id,
                Email: ele?.register_user_email,
                ShippingName: ele?.m_shipping_name,
                Country: ele?.u_country,
                State: ele?.u_state,
                Phone: ele?.m_billing_phone,
                Currency: ele?.currency,
                TotalPrice: ele?.m_total_order_price,
                ShippingPrice: ele?.m_shipping_price,
                CouponAmount: ele?.m_coupon_amount,
                CustomAmount: ele?.customization_amount,
                CustomDiscount: ele?.custom_discount_amount,
                CouponCode: ele?.m_coupon_code,
                ShippingProviderName: ele?.m_shipping_provider_name,
                Flat_discount: ele?.is_custom_flat_discount,
                PaymentMethod: ele?.payment_method,
                Status: ele?.m_order_status,
                OrderMode: ele?.m_order_mode,
                CreatedAt: moment(ele?.m_order_created_at).format("YYYY-MM-DD")
            }
        })

        downloadSheet(filterData, "orders")

        //console.log("filterData",filterData)
    }



    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Orders List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Orders.</p>
                </div>
                {
                    checkPermission[0]?.is_add === "Y" && (
                        <Link

                            to="/add-order"
                            className=" rounded-md  px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                        >
                            ADD ORDERS
                        </Link>
                    )
                }
            </div>
            <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4 bg-white">
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Search Product
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectSku}
                                onChange={(selectedOption) => {
                                    setSelectSku(selectedOption)
                                }}
                                options={skuOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectOrderStatus}
                                onChange={(selectedOption) => {
                                    console.log("selectOrderStatus", selectOrderStatus)
                                    setSelectOrderStatus(selectedOption)
                                }}
                                options={orderStatusOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Shipping Providers
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Customer
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                value={selectCustomerName}
                                getOptionLabel={(option) => option?.fullName ? `${option?.fullName} (${option?.email})` : ""}
                                getOptionValue={(option) => option.value}
                                onChange={(selectedOption) => {
                                    setSelectCustomerName(selectedOption)
                                }}
                                options={customerOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Order Mode
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"

                                value={orderModeSelect}
                                onChange={(selectedOption) => {
                                    console.log("selectedOption", selectedOption)
                                    setSelectOrderMode(selectedOption)
                                }}
                                // // onBlur={handleBlur}
                                options={orderModeOption}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="startDate"
                                value={dateInput.startDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        startDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="flex">
                            <input
                                type="date"
                                name="endDate"
                                styles={customStyles}
                                value={dateInput.endDate}
                                onChange={(e) => {
                                    setDateInput((prev) => ({
                                        ...prev,
                                        endDate: e.target.value
                                    }))
                                }}
                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                            />
                        </div>
                    </div>
                </div>
                <div className=" pt-4 ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium   ml-auto"
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className=" w-full mt-4 flex justify-end">
             
                <button
                    type="button"
                    onClick={downloadExcelBtn}
                    className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-semibold  ml-auto text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    Download Excel
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={order_columns({checkPermission})}
                        data={orderData}
                    />
            }
        </>
    )
}

export default Orders;