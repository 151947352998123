import React, {useState, createContext } from "react";
import MasterServices from "../ApiServices/MasterServices";
export const CustomContext = createContext();


const MyContext = ({ children }) => {
    const [rolePermissionData, setRolePermissionData] = useState(null);
    
    const getRolePermissionData = async (id) => {
        try {
            const response = await MasterServices.getRolePermissionDataApi(id);
            if (response?.data?.data?.length > 0) {
                setRolePermissionData(response.data.data); 
                localStorage.setItem("rolePermissionData",JSON.stringify(response.data.data))
            } else {
                setRolePermissionData([]); 
            }
        } catch (error) {
            console.error(error?.data?.message);
            // toast.error(error?.data?.message);
        }
    };

    const customValue = {
        rolePermissionData,
        getRolePermissionData,
    };

    return (
        <>
            <CustomContext.Provider value={customValue}>
                {children}
            </CustomContext.Provider>
        </>
    )
}

export default MyContext