import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select"
import { PlusCircleIcon, ArrowDownCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import SummaryTable from "../../../components/tables/summarytable";
import { summary_columns } from "../../../components/tables/tableheader";
import { useFormik } from "formik";
import { parsePath, useNavigate, useParams, useLocation } from "react-router-dom";
import { currencyStyles, customStyles } from "../../../helper/customStyles";
import { Switch } from '@headlessui/react'
import { Country, State, City } from 'country-state-city';
import "react-country-state-city/dist/react-country-state-city.css";
import MasterServices from "../../../ApiServices/MasterServices";
import { ToastContainer, toast } from "react-toast";
import Spinner from "../../../components/Spinner";
import moment from "moment";
import debounce from "../../../helper/debounce"
import UseWallet from "../../../components/UseWallet";
import { orderSchema } from "../../../schema";
import { global_epp, global_epp_normal, global_epp_revrse } from "../../../helper/currencyHelper";
import { RotatingLineSpinner } from "../../../components/Spinner";
import BillingAddressComp from "./BillingAddressComp";
import ShippingAddressComp from "./ShippingAddressComp";

const AddOrder = () => {

    const [billingFlag, setBillingFlag] = useState(true);
    const [addressData, setAddressData] = useState([])
    const [loading, setLoading] = useState(true)

    const [country, setCountry] = useState({});
    const [state, setState] = useState({});

    const [shippingPrice, setShippingPrice] = useState(0);
    const [shipCountry, setShipCountry] = useState({})
    const [shipState, setShipState] = useState({})
    const [shippingFlag, setShippingFlag] = useState(true)
    const [shipmentTrackFlag, setShipmentTrackFlag] = useState(true);
    const [custShippingFlag, setCustShippingFlag] = useState(true);
    const [displayShippingPrice, setDisplayShippingPice] = useState(0);


    const [customerData, setCustomerData] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [selectCustomer, setSelectCustomer] = useState({})
    const [selectPaymentMethod, setSelectPaymentMethod] = useState({})

    const [orderStatus, setOrderStatus] = useState({})
    const [orderStatusOption, setOrderStatusOption] = useState([])
    const [productData, setProductData] = useState([])
    const [productOption, setProductOption] = useState([])
    const [selectProduct, setSelectProduct] = useState([])
    const [selectMarkOrder, setSelectMarkOrder] = useState({})
    const [selectShipProvider, setSelectShipProvider] = useState({})
    const [selectOrderAction, setSelectOrderAction] = useState({});
    const [currencyDataOption, setCurrencyDataOption] = useState({})

    const [editOrder, setEditOrder] = useState([])
    const navigate = useNavigate();
    const [couponAmount, setCouponAmount] = useState(0);
    const [walletAmount, setWalletAmount] = useState(0)
    const [couponData, setCouponData] = useState([]);
    const [couponCode, setCouponCode] = useState("");
    const [errorCouponMessage, setErrorCouponMessage] = useState(true);


    const [summaryDataList, setSummaryDataList] = useState([]);
    const [variationData, setVariationData] = useState([])
    const { order_id } = useParams();
    const [openWalletFlag, setOpenWalletFlag] = useState(false);
    const [couponRemoveFlag, setCouponRemoveFlag] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);

    const [walletBalance, setWalletBalance] = useState(0);
    const [customAmount, setCustomAmount] = useState(0);
    const [customAmountFlag, setCustomAmountFlag] = useState(true);
    const [customPrice, setCustomPrice] = useState(0);
    const [itemSubTotal, setItemSubTotal] = useState(0);
    const [enabledEmail, setEnabledEmail] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false)
    const [customDiscountFlag, setCustomDiscountFlag] = useState(true);
    const location = useLocation();

    const [isNormalAmount, setIsNormalAmount] = useState(0)

    const [selectCurrency, setSelectCurrency] = useState({
        label: "INR ₹",
        value: 1
    });

    const [currencyOption, setCurrencyOption] = useState([]);

    const [editItemSubtotal, setEditItemSubtotal] = useState(0)

    const [addressId, setAddressId] = useState({
        m_billing_address_id: null,
        m_shipping_address_id: null
    })


    const [customDiscountAmount, setCustomDiscountAmount] = useState(0);
    const [custDiscountPrice, setCustDiscountPrice] = useState(0)
    const [selectDiscountRadio, setSelectDiscountRadio] = useState({
        isflat: true,
        percentage: false
    })
    const formData = {

        b_firstName: "",
        b_lastName: "",
        b_companyName: "",
        b_addressOne: "",
        b_addressTwo: "",
        b_city: "",
        b_zipCode: "",
        b_email: "",
        b_phone: "",
        b_transactionId: "",
        b_billing_date: "",
        coupon: "",
        tracking_number: "",
        tracking_ship_date: "",

        selectCustomer: "",
        orderStatus: "",
        b_country: "",
        paymentMethod: "",
        s_country: "",

        s_firstName: '',
        s_lastName: "",
        s_addressOne: "",
        s_zipCode: "",
        s_city: "",
        s_phone: "",
        //s_product: []

    }

    const [initialValues, setInitialValues] = useState(formData);
    const [variationProduct, setVariationProduct] = useState([]);
    const [selectedVariations, setSelectedVariations] = useState({});

    const viewFlag = location.pathname.includes("vieworder")

    const [shippingValues, setShippingValues] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        zipCode: "",
        phone: "",
        customerProviderNote: ""
    })






    useEffect(() => {
        const fetchData = async () => {
            await getAllOrderStatus()
        }
        fetchData()
    }, [])


    const getAllOrderStatus = async () => {
        try {

            const response = await MasterServices.getOrderStatus();
            //console.log("response", response)
            if (response?.data?.data?.length > 0) {
                const filter = response?.data?.data.map(ele => ({
                    label: ele?.m_order_status_value,
                    value: ele?.m_order_status_id

                }))
                setOrderStatusOption(filter)
            }

        } catch (error) {
            toast.error(error)
        }
    }

    const paymentMethod = [

        {
            value: 1,
            label: "Payu",
        },
        // {
        //     value: 2,
        //     label: "COD",
        // },
        {
            value: 3,
            label: "Stripe",
        },
        {
            value: 4,
            label: "Others",
        },
    ]

    const markOrder = [
        {
            value: 1,
            label: "Shipped",
            key: "shippped"
        },
        {
            value: 2,
            label: "Partially shipped",
            key: "partially"
        }
    ]

    const customDiscountRadio = [
        {
            value: 1,
            label: "isFlat",
            key: "isflat"
        },
        {
            value: 2,
            label: "Percentage",
            key: "percentage"
        }
    ]

    const shippingProvider = [
        {
            label: 'US',
            options: [
                { label: 'DHL US', value: 'DHL US' },
                { label: 'Associated Global Systems', value: 'Associated Global Systems' },
                { label: 'DHL Parcel', value: 'DHL Parcel' },
                { label: 'TNT', value: 'TNT' },
            ]
        },
        {
            label: 'Austria',
            options: [
                { label: 'DPD Austria', value: 'DPD Austria' },
                { label: 'post.at', value: 'post.at' },
                { label: 'DHL Parcel', value: 'DHL Parcel' },
                { label: 'TNT', value: 'TNT' },
            ]
        },
        {
            label: 'Canada',
            options: [
                { label: 'Canada Post', value: 'Canada Post' },
                { label: 'Canpar', value: 'Canpar' },

            ]
        },
        {
            label: 'Chile',
            options: [
                { label: 'Correos Chile', value: 'Correos Chile' }
            ]
        },
        {
            label: 'China',
            options: [
                { label: 'China Post', value: 'China Post' },
                { label: 'EMS', value: 'EMS' },
            ]
        },
        {
            label: 'India',
            options: [
                { label: 'By Hand Delivery', value: 'By Hand Delivery' },

            ]
        },
    ];


    const orderAction = [
        {
            value: 1,
            label: "Email invoice / order details to customer"
        },
        {
            value: 2,
            label: "Resend new order notification"
        },
        {
            value: 3,
            label: "Regenerate download permissions"
        },
        {
            value: 4,
            label: "Export Tracking to PayPal"
        },
        {
            value: 5,
            label: "Recalculate order cashback"
        },
    ]

    // const validateCustomForm = () => {
    //     let isValid = true;
    //     const errors = { s_firstName: '', s_lastName: '', s_addressOne: "", s_zipCode: "", s_city: "", s_phone: "" };

    //     if (!firstName) {
    //         errors.s_firstName = 'First Name is required';
    //         isValid = false;
    //     }
    //     if (!lastName) {
    //         errors.s_lastName = 'Last Name is required';
    //         isValid = false;
    //     }
    //     if (!addressOne) {
    //         errors.s_addressOne = 'Address One is required';
    //         isValid = false;
    //     }
    //     if (!city) {
    //         errors.s_city = 'City is required';
    //         isValid = false;
    //     }
    //     if (!zipCode) {
    //         errors.s_zipCode = 'ZipCode is required';
    //         isValid = false;
    //     }
    //     if (!phone) {
    //         errors.s_phone = 'Phone is required';
    //         isValid = false;
    //     }

    //     setCustomErrors(errors);
    //     return isValid;
    // };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldError,
        getFieldProps,
        validateForm,
        handleSubmit,
        setTouched,
        setErrors
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        //validationSchema: orderSchema,
        onSubmit: async (values) => {
            console.log("summData", summaryDataList)
            const productSend = summaryDataList?.length > 0 && summaryDataList?.map((ele) => ele?.p_product_sku).join(',');
            const productIdSend = summaryDataList?.length > 0 && summaryDataList?.map((ele) => ele?.p_porduct_id).join(',');
            const variationIdSend = summaryDataList?.length > 0 && summaryDataList?.filter(ele => ele?.p_vari_id).map((ele) => ele?.p_vari_id).join(',');
            const walletAmountSend = walletAmount ? selectCurrency?.label == "INR ₹" ? parseInt(walletAmount) : Math.round(parseFloat(global_epp_revrse(walletAmount, selectCurrency.label))) : 0;
            const customAmountSend = customPrice ? selectCurrency?.label == "INR ₹" ? parseInt(customPrice) : parseInt(global_epp_revrse(customPrice, selectCurrency.label)) : 0
            const customShippingAmountSend = shippingPrice ? selectCurrency?.label == "INR ₹" ? parseInt(shippingPrice) : global_epp_revrse(shippingPrice, selectCurrency.label) : 0;
            const customDiscountAmountSend = selectDiscountRadio?.percentage ? selectCurrency?.label == "INR ₹" ? parseInt(isNormalAmount) : global_epp_revrse(isNormalAmount, selectCurrency.label) : selectCurrency?.label == "INR ₹" ? parseInt(custDiscountPrice) : global_epp_revrse(custDiscountPrice, selectCurrency.label);

            const body = {
                ...values,
                s_firstName: shippingValues?.firstName,
                s_lastName: shippingValues?.lastName,
                s_compnayName: shippingValues?.companyName,
                s_addressOne: shippingValues?.addressOne,
                s_addressTwo: shippingValues?.addressTwo,
                s_city: shippingValues?.city,
                s_zipCode: shippingValues?.zipCode,
                s_phone: shippingValues?.phone,
                customer_provider_note: shippingValues?.customerProviderNote ? shippingValues?.customerProviderNote : "",
                s_shipCountry: shipCountry ? shipCountry?.name : "",
                s_state: shipState ? shipState?.name : "",

                customername: selectCustomer?.value ? selectCustomer?.value : null,
                orderStatus: orderStatus ? orderStatus.label : "",
                product: productSend,
                p_product_id: productIdSend,
                m_shipping_provider_id: selectShipProvider?.value ? selectShipProvider?.value : "",
                m_shipping_provider_name: selectShipProvider?.label ? selectShipProvider?.label : "",

                Shipped: selectMarkOrder?.shipped ? true : false,
                Partially_shipped: selectMarkOrder?.partially ? true : false,

                orderAction: selectOrderAction?.label ? selectOrderAction.label : null,
                b_country: country ? country?.name : "",
                b_state: state ? state?.name : "",

                payment_method: selectPaymentMethod?.label ? selectPaymentMethod?.label : null,
                m_billing_address_id: addressId?.m_billing_address_id ? addressId?.m_billing_address_id : null,
                m_shipping_address_id: addressId?.m_shipping_address_id ? addressId?.m_shipping_address_id : null,

                m_order_id: order_id ? parseInt(order_id) : null,
                m_total_order_price: itemSubTotal ? itemSubTotal : null,
                m_coupon_amount: couponAmount ? couponAmount : null,
                m_wallet_points_applied: walletAmountSend,
                m_wallet_balance: walletBalance,
                customization_amount: customAmountSend,
                custom_shipping_amount: customShippingAmountSend,
                is_email_sent: enabledEmail ? "Y" : "N",
                is_custom_flat_discount: selectDiscountRadio?.isflat === true ? "Y" : "N",
                custom_discount_amount: customDiscountAmountSend,
                currency: selectCurrency?.label ? selectCurrency?.label : null,
                m_veriable_product_id: variationIdSend ? variationIdSend : null

            }



            console.log("body", body)
            if (editOrder?.length > 0 && order_id) {
                editOrderSubmit(body)
            } else {
                addOrderSubmit(body)
            }
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            await getUsers();
            await getProducts();
            await getCurrency();
            await currencyConversionData()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (customerData?.length > 0 && productData?.length > 0 && currencyOption?.length > 0) {
            setLoading(false);
        }

        if (customerData?.length > 0 && selectCustomer?.value) {
            customerData.forEach((ele) => {
                if (parseInt(ele?.u_user_id) === selectCustomer?.value) {
                    setWalletBalance(ele?.m_wallet_balance)
                }
            })

        }

        if (selectCustomer?.value) {
            getAddress(selectCustomer?.value)
        }

    }, [customerData, productData, selectCustomer, currencyOption]);

    const getSingleOrder = async (id) => {
        try {

            const response = await MasterServices.getOrderById(id);
            //console.log("getSingleOrder", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditOrder(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }

    }

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        //console.log("response", response?.data?.data)

        if (response?.data?.data?.length > 0) {
            const optionData = []
            //console.log("getUsers", response?.data?.data)
            setCustomerData(response?.data?.data)

            response?.data?.data.forEach((ele) => {


                optionData.push({
                    value: ele?.u_user_id,
                    fullName: ele?.u_user_first_name + " " + ele?.u_user_last_name,
                    email: ele?.u_user_email
                })

            })
            setCustomerOption(optionData)
        } else {
            setCustomerData([])
        }
    }

    const getProducts = async () => {
        try {

            const response = await MasterServices.getAllProduct();
            //console.log("getProducts", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setProductData(response?.data?.data)
                //console.log("setProductData", response?.data?.data)
                let dataoption = response?.data?.data?.map((ele) => {
                    if (!order_id) {


                        let product_qunatity = ele?.p_vari_count ? ele?.p_vari_count : ele?.p_stok_quantity
                        if (parseInt(product_qunatity) > 0) {
                            return {
                                ...ele,
                                value: ele?.p_porduct_id,
                                label: ele?.p_product_sku
                            };
                        }
                    }
                    else {
                        return {
                            ...ele,
                            value: ele?.p_porduct_id,
                            label: ele?.p_product_sku
                        };
                    }
                    //return null
                }).filter(Boolean);

                //console.log('dataoption', dataoption)
                if (dataoption?.length > 0) {
                    setProductOption(dataoption)
                }


            }
        } catch (error) {
            toast.error("error in getProducts")
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (order_id) {
                await getSingleOrder(order_id)
            }
        }
        fetchData()
    }, [order_id])

    const DateFormatter = (bdate) => {
        // const dateStr = "2024-06-13T00:00:00.000Z";
        const date = new Date(bdate);

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getUTCFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        //console.log("formattedDate", formattedDate)
        return formattedDate
    }


    useEffect(() => {
        if (order_id && editOrder?.length > 0) {
            editOrder.forEach((item) => {

                if (item?.address?.length > 0) {
                    item?.address.forEach((ele) => {

                        if (ele?.u_address_type === "billing") {
                            setInitialValues({
                                ...values,
                                b_firstName: item?.m_billing_name?.split(' ')[0],
                                b_lastName: item?.m_billing_name?.split(' ')[1],
                                b_companyName: item?.m_billing_company_name,
                                b_addressOne: ele?.u_address_value_1,
                                b_addressTwo: ele?.u_address_value_2,
                                b_city: ele?.u_city,
                                b_zipCode: ele?.u_pin_number,
                                b_email: item?.m_billing_email,
                                b_phone: item?.m_billing_phone,

                            })
                            if (ele?.u_country || ele?.u_state) {
                                const country = Country.getAllCountries().find(
                                    (c) => c?.name === ele?.u_country
                                );

                                const allState = State.getStatesOfCountry(country?.isoCode);
                                const state = allState.find((s) => s.name === ele?.u_state);
                                setCountry(country)
                                setState(state)
                                //console.log("countryJay",state)

                                if (country) {
                                    //console.log("b_country", country)
                                    setFieldValue("b_country", country?.name);
                                    errors.b_country = ""
                                    touched.b_country = ""
                                }



                            }

                            if (ele?.u_address_id) {
                                setAddressId((prev) => ({
                                    ...prev,
                                    m_billing_address_id: ele?.u_address_id
                                }))
                            }

                        }
                        if (ele?.u_address_type === "shipping") {

                            setShippingValues((prev) => ({
                                ...prev,
                                firstName: item?.m_shipping_name?.split(' ')[0],
                                lastName: item?.m_shipping_name?.split(' ')[1],
                                companyName: item?.m_shipping_company_name,
                                addressOne: ele?.u_address_value_1,
                                addressTwo: ele?.u_address_value_2,
                                city: ele?.u_city,
                                zipCode: ele?.u_pin_number,
                                phone: item?.m_shipping_phone,
                            }))



                            setFieldValue("s_firstName", item?.m_shipping_name?.split(' ')[0]);

                            setShipCountry(prev => ({
                                ...prev,
                                label: ele?.u_country
                            }))



                            if (ele?.u_country) {
                                if (ele?.u_country || ele?.u_state) {
                                    const country = Country.getAllCountries().find(
                                        (c) => c?.name === ele?.u_country
                                    );

                                    const allState = State.getStatesOfCountry(country?.isoCode);
                                    const state = allState.find((s) => s.name === ele?.u_state);
                                    // console.log("countryJay",state)
                                    setShipCountry(country)
                                    setShipState(state)

                                    if (country) {

                                        setFieldValue("s_country", country?.name);
                                        errors.s_country = ""
                                        touched.s_country = ""
                                    }
                                }

                            }
                            if (ele?.u_address_id) {
                                setAddressId((prev) => ({
                                    ...prev,
                                    m_shipping_address_id: ele?.u_address_id
                                }))
                            }
                        }
                        else {

                            setInitialValues((prev) => ({
                                ...prev,
                                b_transactionId: item?.m_transction_id,

                                coupon: item?.m_coupon_code,
                                b_billing_date: DateFormatter(item?.m_billing_date),
                                tracking_number: item?.m_order_tracking_number,
                                tracking_ship_date: DateFormatter(item?.m_tarcking_ship_date),
                            }))
                        }
                    })
                }
                if (item?.m_order_status) {

                    orderStatusOption?.forEach((ele) => {
                        // console.log("orderStatusOption>>>>>>>>>>>> 1", item?.m_order_status)
                        // console.log("orderStatusOption>>>>>>>>>>>> 2", ele?.label)

                        let order_status = item?.m_order_status === "Initiated" ? "Pending Payment" : item?.m_order_status?.includes("Placed") || item?.m_order_status?.includes("Purchese") ? "Processing" : item?.m_order_status
                        if (ele?.label.toLowerCase() === order_status.toLowerCase()) {

                            //console.log(ele, "ele >>>>>>>>>>>>>>>>>>>")

                            setOrderStatus({
                                value: ele?.value,
                                label: ele?.label
                            })
                            setFieldValue("orderStatus", ele?.value);
                            errors.orderStatus = ""
                            touched.orderStatus = ""

                        }
                        if (ele?.label === "Cancelled" && item?.m_order_status === "Cancelled") {
                            setEnabledEmail(false)
                        }
                    })



                }

                if (parseInt(item?.m_coupon_amount)) {
                    const m_coupon_amount = item?.m_coupon_amount === null || !item?.m_coupon_amount ? 0 : parseInt(item?.m_coupon_amount)
                    setCouponAmount(parseInt(m_coupon_amount))
                }
                if (parseInt(item?.m_wallet_points_applied)) {
                    const walletAmountData = Math.round(parseFloat(global_epp_normal(item?.m_wallet_points_applied, item?.currency)))
                    setWalletAmount(parseInt(walletAmountData))
                }
                if (item?.m_coupon_code) {
                    setCouponRemoveFlag(false)
                }

                if (item?.payment_method) {
                    paymentMethod?.length > 0 && paymentMethod.forEach((ele) => {
                        if (ele?.label === item?.payment_method) {
                            setSelectPaymentMethod({
                                label: ele?.label,
                                value: ele?.value
                            })
                            setFieldValue("paymentMethod", ele?.value);
                            errors.paymentMethod = ""
                            touched.paymentMethod = ""
                        }
                    })
                }

                if (item?.order_action) {
                    orderAction?.length > 0 && orderAction.forEach((ele) => {
                        if (ele?.label === item?.order_action) {
                            setSelectOrderAction({
                                label: ele?.label,
                                value: ele?.value
                            })
                        }
                    })
                }

                if (parseInt(item?.m_shipping_price) && item?.currency) {
                    const ddd = Math.round(global_epp_normal(parseInt(item?.m_shipping_price), item?.currency))
                    setShippingPrice(ddd)
                    setCustShippingFlag(false)
                    setDisplayShippingPice(ddd)
                }

                if (item?.m_shipped === "Y" || item?.m_partially_shipped === "Y") {
                    setSelectMarkOrder({
                        partially: item?.m_partially_shipped === "Y" ? true : false,
                        shippped: item?.m_shipped === "Y" ? true : false
                    })
                }

                if (item?.u_user_id) {

                    customerOption?.length > 0 && customerOption?.forEach((ele) => {
                        if (parseInt(ele?.value) === parseInt(item?.u_user_id)) {

                            setSelectCustomer({
                                value: ele?.value,
                                fullName: ele?.fullName,
                                email: ele?.email
                            })
                            setFieldValue("selectCustomer", ele?.value);
                            errors.selectCustomer = ""
                            touched.selectCustomer = ""
                        }
                    })
                }
                if (item?.currency) {
                    //console.log("currencyOption", currencyOption);
                    currencyOption?.length > 0 && currencyOption.forEach((ele) => {
                        if (ele?.label === item?.currency) {
                            setSelectCurrency({
                                label: ele?.label,
                                value: ele?.value
                            })
                        }
                    })
                }

                if (item?.m_shipping_provider_id || item?.m_shipping_provider_name) {

                    shippingProvider?.forEach((ele) => {
                        ele?.options?.forEach((sp) => {
                            if (sp?.label === item?.m_shipping_provider_name) {
                                setSelectShipProvider({
                                    value: sp?.value,
                                    label: sp?.label
                                })
                            }

                        })
                    })

                }

                if (item?.customization_amount > 0 && item?.currency) {
                    const ddd = Math.round(global_epp_normal(parseInt(item?.customization_amount), item?.currency))
                    setCustomAmount(ddd)
                    setCustomAmountFlag(false)
                    setCustomPrice(ddd)
                }

                if (item?.p_product_id) {
                    //console.log("productOption", productOption)
                    const productIds = item?.p_product_id?.split(',').map(id => parseInt(id));
                    //console.log("productIds", productIds)
                    const filterData = productOption?.filter(ele => {
                        return productIds?.some(ee => parseInt(ee) === parseInt(ele?.value)); // Fixed typo here
                    });

                    // console.log("filterData", {
                    //     p_product_id: item?.p_product_id,
                    //     productIds: productIds,
                    //     filterData: filterData
                    // })
                    if (filterData?.length > 0) {
                        setSelectProduct(filterData);
                        setSummaryDataList(filterData)
                    }
                }
                if (item?.m_veriable_product_id) {
                    const varIds = item?.m_veriable_product_id?.split(',').map(id => parseInt(id));

                    setSelectedVariations((prev) => {
                        const updatedVariations = { ...prev };
                        varIds.forEach(id => {
                            updatedVariations[id] = true; // Mark each variation ID as selected
                        });
                        //console.log("varIds",updatedVariations)
                        return updatedVariations;
                    });
                }

                if (parseInt(item?.m_total_order_price)) {
                    setEditItemSubtotal(parseInt(item?.m_total_order_price))
                }

                if (item?.is_custom_flat_discount === "Y" || item?.is_custom_flat_discount === "N" || parseInt(item?.custom_discount_amount)) {
                    setSelectDiscountRadio({
                        isflat: item?.is_custom_flat_discount === "Y" ? true : false,
                        percentage: item?.is_custom_flat_discount === "N" ? true : false
                    })


                    if (item?.is_custom_flat_discount === "N" && item?.currency) {
                        let custD = parseFloat(item?.custom_discount_amount);
                        let totalPr = parseFloat(item?.m_total_order_price)
                        let tempPrice = (global_epp_normal(custD, item?.currency) / global_epp_normal(totalPr, item?.currency)) * 100

                        setCustomDiscountAmount(Math.round(parseFloat(tempPrice)))
                    }
                    if (item?.is_custom_flat_discount === "Y" && item?.currency) {
                        let custD = global_epp_normal(parseFloat(item?.custom_discount_amount), item?.currency)
                        //console.log(">>>>>>custD", custD)
                        setCustomDiscountAmount(custD)
                    }

                    handleCustomDiscount()



                }



            })
        }
    }, [editOrder, productOption, customerOption, orderStatusOption, currencyOption])


    const editOrderSubmit = async (body) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editOrders(body);
            // console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("Order Edit Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while editing the order")
        }
    }

    const addOrderSubmit = async (body) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addOrders(body);
            //console.log("body", response)
            if (response?.data?.success === true) {
                setSubmitLoader(false)
                toast.success("Order Added Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                setSubmitLoader(false)
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error("An error occurred while adding the order")
        }
    }


    const copyBillingHandle = () => {

        if (values.b_firstName) {
            //setFirstName()

            setShippingValues((prev) => ({
                ...prev,
                firstName: values.b_firstName
            }))
            setFieldValue("s_firstName", values.b_firstName);
            setTouched("s_firstName", false)

        }

        if (values.b_lastName) {
            //setLastName(values.b_lastName);
            setShippingValues((prev) => ({
                ...prev,
                lastName: values.b_lastName
            }))
            setFieldValue("s_lastName", values.b_lastName);
            setTouched("s_lastName", false)
        }

        if (values.b_addressOne) {
            // setAddressOne(values.b_addressOne);
            setShippingValues((prev) => ({
                ...prev,
                addressOne: values.b_addressOne
            }))
            setFieldValue("s_addressOne", values.b_addressOne);
            setTouched("s_addressOne", false)

        }
        if (values.b_addressTwo) {
            // setAddressOne(values.b_addressOne);
            setShippingValues((prev) => ({
                ...prev,
                addressTwo: values.b_addressTwo
            }))



        }

        if (values.b_city) {
            //setCity(values.b_city);
            setShippingValues((prev) => ({
                ...prev,
                city: values.b_city
            }))
            setFieldValue("s_city", values.b_city);
            setTouched("s_city", false)
        }

        if (values?.b_zipCode) {
            // setZipCode(values.b_zipCode);
            setShippingValues((prev) => ({
                ...prev,
                zipCode: values.b_zipCode
            }))
            setFieldValue("s_zipCode", values.b_zipCode);
            setTouched("s_zipCode", false)
        }

        if (values.b_phone) {
            //setPhone(values.b_phone);
            setShippingValues((prev) => ({
                ...prev,
                phone: values.b_phone
            }))
            setFieldValue("s_phone", values.b_phone);
            setTouched("s_phone", false)
        }
        if (values.b_companyName) {
            setShippingValues((prev) => ({
                ...prev,
                companyName: values.b_companyName
            }))
        }

        if (country) {
            //console.log("country", country)
            setShipCountry(country)
            setFieldValue("s_country", country?.name);
            errors.s_country = ""
            touched.s_country = ""
        }

        //setCompanyName(values.b_companyName);
        //setAddressTwo(values.b_addressTwo);
        setShipState(state)
    }

    const clearForm = () => {
        navigate("/order-page")

    }

    const handleMarkOrder = (name, isChecked) => {

        const updatedState = markOrder.reduce((acc, ele) => {
            acc[ele.key] = ele.key === name;
            return acc;
        }, {});

        //console.log("updatedState", updatedState)
        setSelectMarkOrder(updatedState);

    }

    const handleDiscountRadio = (name, isChecked) => {
        const updatedState = customDiscountRadio.reduce((acc, ele) => {
            acc[ele.key] = ele.key === name;
            return acc;
        }, {});

        if (updatedState.percentage) {
            removeCustomDiscount()
        }

        if (updatedState.isflat) {
            setCustomDiscountFlag(true)
        }
        //console.log("updatedState", updatedState)
        setSelectDiscountRadio(updatedState);
    }


    const totalHandle = (itemTotal, cpAmount, wAmount, customAmount, shippingPrice, selectDiscountRadio, custDiscountPrice) => {
        const itemTotalFinal = editItemSubtotal ? editItemSubtotal : itemTotal
        if (selectDiscountRadio?.percentage) {
            const perc = global_epp_normal(parseInt(custDiscountPrice), selectCurrency?.label);
            const percentage = parseFloat(perc);


            const finalCpAmount = (itemTotalFinal * percentage) / 100;

            const finalCustomDiscount = itemTotalFinal - finalCpAmount;

            const convertFinalAmount = global_epp_normal(finalCpAmount, selectCurrency?.label)
            console.log("totalHandle", {
                custDiscountPrice,
                perc,
                percentage,
                convertFinalAmount
            })

            setIsNormalAmount(convertFinalAmount);
            // console.log("finalCpAmount", {
            //     itemTotalFinal,
            //     percentage,
            //     finalCpAmount,
            //     custDiscountPrice,
            //     finalCustomDiscount,
            //     convertFinalAmount
            // })

            const result = ((itemTotalFinal - cpAmount - finalCpAmount) + shippingPrice + customAmount) - wAmount

            return result;
        } else {

            const result = ((itemTotalFinal - cpAmount - custDiscountPrice) + shippingPrice + customAmount) - wAmount
            //console.log("result", result)

            return result;
        }



    }

    // console.log("selectCurrency", selectCurrency)

    const getAddress = async (id) => {
        try {
            const response = await MasterServices.getAddressById(id);
            //console.log("getAddress", response)
            if (response?.data?.data?.length > 0) {
                setAddressData(response?.data?.data)
            } else {
                setAddressData([])
            }
        }
        catch (error) {
            console.log("getAddress", error)
        }
    }


    const loadBillingHandle = () => {
        if (addressData?.length > 0) {
            addressData?.forEach((ele) => {
                if (ele?.u_address_type === "billing") {
                    const Name = ele?.u_user_name ? ele?.u_user_name?.split(" ") : ""
                    //console.log("loadBillingHandle", Name)
                    setInitialValues(({
                        b_firstName: Name[0],
                        b_lastName: Name[1],
                        b_companyName: ele?.u_company_name,
                        b_addressOne: ele?.u_address_value_1,
                        b_addressTwo: ele?.u_address_value_2,
                        b_city: ele?.u_city,
                        b_zipCode: ele?.u_pin_number,
                        b_email: ele?.u_email_address,
                        b_phone: ele?.u_mobile_number,
                        coupon: ""

                    }))

                    if (ele?.u_country || ele?.u_state) {
                        const country = Country.getAllCountries().find(
                            (c) => c?.name === ele?.u_country
                        );

                        const allState = State.getStatesOfCountry(country?.isoCode);
                        const state = allState.find((s) => s.name === ele?.u_state);
                        //console.log("countryJay",state)
                        setCountry(country)
                        setState(state)

                    }
                }
            })
        }
    }

    const loadShippingHandle = () => {
        if (addressData?.length > 0) {
            addressData?.forEach((ele) => {

                if (ele?.u_address_type === "shipping") {
                    const Name = ele?.u_user_name ? ele?.u_user_name?.split(" ") : ""
                    // console.log("loadBillingHandle", Name)


                    setShippingValues((prev) => ({
                        ...prev,
                        firstName: Name[0],
                        lastName: Name[1],
                        companyName: ele?.u_company_name,
                        addressOne: ele?.u_address_value_1,
                        addressTwo: ele?.u_address_value_2,
                        city: ele?.u_city,
                        zipCode: ele?.u_pin_number,
                        phone: ele?.u_mobile_number,
                    }))

                    if (ele?.u_country || ele?.u_state) {
                        const country = Country.getAllCountries().find(
                            (c) => c?.name === ele?.u_country
                        );

                        const allState = State.getStatesOfCountry(country?.isoCode);
                        const state = allState.find((s) => s.name === ele?.u_state);
                        // console.log("countryJay",state)
                        setShipCountry(country)
                        setShipState(state)
                    }
                }
            })
        }
    }


    const applyCouponVoucher = async () => {

        setLoading(true)
        try {
            if (couponCode) {


                const payload = {
                    m_voucher_code: couponCode,
                    amount: itemSubTotal,
                    date: moment().format("YYYY-MM-DD"),
                }
                //console.log("applyCouponVoucher", payload)
                const response = await MasterServices.applyCoupon(payload);
                // console.log("applyCouponVoucher", response)
                if (response?.data?.success) {
                    toast.success("Coupon Applied")
                    setCouponRemoveFlag(false)
                    setCouponData(response?.data?.data);
                    const couponState = response?.data?.data;
                    const couponDiscount = couponState?.is_flat === "Y" ? (parseInt(couponState?.orignal_amount) / 100) * parseInt(couponState?.discount_percentage) : parseInt(couponState?.discount_percentage);

                    setCouponAmount(couponDiscount)


                    setLoading(false)
                } else {
                    toast.error(response?.data?.message)
                    setCouponRemoveFlag(false)
                    setCouponCode("")
                    setCouponData([])
                    setLoading(false)
                }
            }
        }
        catch (error) {
            // console.log("applyCouponVoucher", error);
            toast.error(error?.data?.message)
            setCouponCode("")
            setLoading(false)
        }
    }

    const removeCouponVoucher = () => {
        values.coupon = ""
        setCouponAmount(0)
        setCouponRemoveFlag(true);
        setCouponCode("")
    }


    useEffect(() => {
        if (selectProduct?.length > 0 && shipCountry?.name) {
            setErrorCouponMessage(false);
        }


        if (itemSubTotal || (couponAmount === 0 || couponAmount) || walletAmount > 0 || (customPrice === 0 || customPrice || shippingPrice || custDiscountPrice)) {

            // console.log(selectCurrency.label, "selectCurrency.label >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

            let shippingPriceTemp = selectCurrency?.label == "INR ₹" ? parseInt(shippingPrice) : global_epp_revrse(shippingPrice, selectCurrency.label)
            //console.log("shippingPriceTemp", shippingPriceTemp, "shippingPrice", shippingPrice)
            let customPriceTemp = selectCurrency?.label == "INR ₹" ? parseInt(customPrice) : global_epp_revrse(customPrice, selectCurrency.label)
            let custDiscountPriceTemp = selectCurrency?.label == "INR ₹" ? parseInt(custDiscountPrice) : global_epp_revrse(custDiscountPrice, selectCurrency.label)
            let walletAmountTemp = selectCurrency?.label == "INR ₹" ? parseInt(walletAmount) : global_epp_revrse(walletAmount, selectCurrency.label)
            //console.log(shippingPriceTemp, "shippingPriceTemp >>>>>>>>>>>>")

            let totalAmt = totalHandle(
                itemSubTotal,
                couponAmount,
                parseInt(walletAmountTemp),
                parseInt(customPriceTemp),
                parseInt(shippingPriceTemp),
                selectDiscountRadio,
                parseInt(custDiscountPriceTemp)
            );
            setTotalAmount(totalAmt);
        }




        if (selectProduct?.length > 0) {


            const variationProducts = [];
            const nonVariationProducts = [];


            selectProduct?.forEach((ele) => {
                if (ele?.variationForProduct?.length > 0) {

                    variationProducts.push(ele);
                } else {

                    nonVariationProducts.push(ele);
                }
            });

            setSummaryDataList((prev) => {
                const updatedSummary = [...prev];
                //console.log("updatedSummary",updatedSummary)
                nonVariationProducts.forEach((nonVarProduct) => {

                    const exists = updatedSummary.some(item => item.p_porduct_id === nonVarProduct.p_porduct_id);
                    if (!exists) {
                        updatedSummary.push(nonVarProduct);
                    }
                });

                return updatedSummary;
            });
            //console.log("variationProducts",variationProducts)


            const mergedProducts = Object.values(
                variationProducts.reduce((acc, product) => {
                    const { p_porduct_id, p_product_name, p_product_sku, variationForProduct } = product;


                    if (!acc[p_porduct_id]) {
                        acc[p_porduct_id] = {
                            p_porduct_id,
                            p_product_name,
                            p_product_sku,
                            variations: []
                        };
                    }


                    variationForProduct.forEach(({ p_vari_id, p_vari_name, reg_price, p_vari_count }) => {
                        acc[p_porduct_id].variations.push({
                            p_vari_id,
                            p_vari_name,
                            reg_price,
                            p_vari_count
                        });
                    });

                    return acc;
                }, {})
            );


            setVariationData(mergedProducts);


            setSummaryDataList((prev) => {
                return prev.filter(item => {
                    return selectProduct.some(selectedItem => selectedItem.p_porduct_id === item.p_porduct_id);
                });
            });
        }

        if (selectProduct?.length <= 0) {
            setItemSubTotal(0)
            setSummaryDataList([])
            setVariationData([]);
        }
        //handleA

    }, [selectProduct, couponAmount, shipCountry, walletAmount, customPrice, itemSubTotal, shippingPrice, selectDiscountRadio, custDiscountPrice]);

    useEffect(() => {
        console.log("summaryDataList", summaryDataList)
        if (summaryDataList?.length > 0) {

            const filterData = summaryDataList?.map((ele) => {
                const price = ele?.p_vari_id ? parseInt(ele?.reg_price) : parseInt(ele?.p_product_price)
                const stockQuantity = ele?.p_vari_id ? 1 : parseInt(ele?.p_stok_quantity) > 0 ? 1 : 1;

                return price * parseInt(stockQuantity);

            });

            const totalPrice = filterData.reduce((acc, curr) => acc + curr, 0);
            setItemSubTotal(totalPrice);


        }
    }, [summaryDataList])

    const applyCustomAmount = () => {
        if (customAmount) {

            setCustomPrice(parseInt(customAmount))
            setCustomAmountFlag(false)
        }
    }

    const removeCustomAmount = () => {
        setCustomPrice(0)
        setCustomAmount(0)
        setCustomAmountFlag(true)
    }

    const applyCustomShippingAmount = () => {
        if (displayShippingPrice) {
            setShippingPrice(parseInt(displayShippingPrice))
            setCustShippingFlag(false)
        }
    }

    const removeCustomShippingAmount = () => {
        setShippingPrice(0)
        setDisplayShippingPice(0)
        setCustShippingFlag(true)
    }



    const handleCustomDiscount = () => {
        if (customDiscountAmount) {
            setCustDiscountPrice(customDiscountAmount)
        }
        setCustomDiscountFlag(false)
    }

    const removeCustomDiscount = () => {
        setCustDiscountPrice(0)
        setCustomDiscountAmount(0)
        setCustomDiscountFlag(true)
    }

    const getCurrency = async () => {
        try {
            const respose = await MasterServices.getCurrency();
            //console.log("getCurrency", respose)
            if (respose?.data?.data?.length > 0) {
                const filterData = respose?.data?.data?.map((ele) => ({
                    label: ele?.m_currency_short_code,
                    value: ele?.m_currency_id
                }))
                setCurrencyOption(filterData)
            } else {
                setCurrencyOption([])
            }

        }
        catch (error) {
            console.log("error", error)
        }
    }

    const currencyConversionData = async () => {
        try {
            const respose = await MasterServices.getCurrencyConversionData();
            //console.log("currencyConversionData", respose)
            if (respose?.data?.data) {
                localStorage.setItem(
                    "currencyConversionData",
                    JSON.stringify(respose.data.data)
                );

                setCurrencyDataOption(respose?.data?.data)
            } else {
                setCurrencyDataOption({})
            }

        }
        catch (error) {
            console.log("error", error)
        }
    }

    //console.log("selectDiscountRadio", selectDiscountRadio)

    const withDrawHandler = async () => {
        // console.log("walletAmount", walletAmount)
        if (walletAmount > walletBalance || parseInt(walletAmount) <= 0) {
            toast.info("Check your wallet balance & select customer to debit wallet amount")
        }
        else {
            setOpenWalletFlag(false)


        }
    }



    const handleAttributeVariation = (varID, item, isSelected) => {
        if (isSelected) {

            setSummaryDataList((prev) => {
                return prev.filter(ele => ele.p_vari_id !== varID);
            });
        } else {

            const selectedVariation = item.variations.find(variation => variation.p_vari_id === varID);
            setSummaryDataList((prev) => {
                const exists = prev.some(ele => ele.p_vari_id === varID);
                if (!exists && selectedVariation) {
                    return [
                        ...prev,
                        {
                            p_porduct_id: item.p_porduct_id,
                            p_product_name: item.p_product_name,
                            p_product_sku: item.p_product_sku,
                            ...selectedVariation
                        }
                    ];
                }
                return prev;
            });
        }
    };

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    useEffect(() => {
        if (!values.b_billing_date) {
            setFieldValue('b_billing_date', getCurrentDate());
        }
    }, [setFieldValue, values.b_billing_date]);


    //console.log("selectedVariations",selectedVariations)
    return (
        <>
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold text-gray-900">{order_id ? (viewFlag ? "View Order" : "Edit Order") : "Add Order"}</h1>
                <div className="flex flex-col ">
                    <Select
                        classNamePrefix="select"
                        placeholder="select"
                        isDisabled={order_id ? true : false}
                        value={selectCurrency}
                        onChange={(selectedOption) => {
                            //console.log("selectCurrency", selectCurrency)
                            setSelectCurrency(selectedOption)
                            setSelectProduct([])
                            setSummaryDataList([])
                            removeCustomDiscount();
                            removeCustomAmount();
                            removeCouponVoucher();
                            removeCustomShippingAmount()
                            setWalletAmount(0)
                            setSelectDiscountRadio({
                                isflat: true,
                                percentage: false
                            })
                        }}
                        //defaultValue={currencyOption[0]?.label}
                        name="selectCurrency"
                        className=" sm:text-sm w-[150px] "
                        options={currencyOption}
                        styles={customStyles}
                    />

                </div>

            </div>
            <div className="py-5">
                <form className="space-y-5" onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                }}>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm border-2 border-gray-200" >
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer
                            </label>
                            <div className="flex flex-col">
                                <Select
                                    classNamePrefix="select"
                                    name="selectCustomer"
                                    value={selectCustomer}
                                    getOptionLabel={(option) => option?.fullName ? `${option?.fullName} (${option?.email})` : ""}
                                    getOptionValue={(option) => option.value}
                                    isDisabled={viewFlag ? true : false}
                                    onChange={(selectOption) => {
                                        // console.log("setSelectCustomer", selectOption)
                                        setSelectCustomer(selectOption)
                                        setFieldValue('selectCustomer', selectOption ? selectOption.value : '');
                                    }
                                    }
                                    className="basic-single sm:text-sm"
                                    options={customerOption}
                                    styles={customStyles}

                                />
                                {errors.selectCustomer && touched.selectCustomer && (
                                    <div className="text-red-600 text-sm">{errors.selectCustomer}</div>
                                )}

                            </div>
                        </div>
                        <div className="">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Status
                            </label>
                            <div className="flex flex-col">
                                <Select
                                    classNamePrefix="select"
                                    placeholder="select"
                                    value={orderStatus}
                                    isDisabled={viewFlag ? true : false}
                                    onChange={(selectOption) => {
                                        //console.log("selectOption", selectOption)
                                        setOrderStatus(selectOption)
                                        if (selectOption?.label === "Cancelled") {
                                            setEnabledEmail(false)
                                        } else {
                                            setEnabledEmail(true)
                                        }
                                        setFieldValue('orderStatus', selectOption ? selectOption.value : '');
                                    }}
                                    name="orderStatus"
                                    className=" sm:text-sm"
                                    options={orderStatusOption}
                                    styles={customStyles}
                                />
                                {errors.orderStatus && touched.orderStatus && (
                                    <div className="text-red-600 text-sm">{errors.orderStatus}</div>
                                )}

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Date Picker
                            </label>
                            <div className="flex flex-col">
                                <input
                                    type="date"
                                    name="b_billing_date"
                                    disabled={viewFlag ? true : false}

                                    // value={values.b_billing_date}
                                    value={values.b_billing_date || getCurrentDate()}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}

                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />
                                {/* {errors.b_billing_date && touched.b_billing_date && (
                                    <div className="text-red-600 text-sm">{errors.b_billing_date}</div>
                                )} */}
                            </div>
                        </div>
                    </div>


                    {/* {billing address} */}
                    <BillingAddressComp
                        values={values}
                        billingFlag={billingFlag}
                        setBillingFlag={setBillingFlag}
                        loadBillingHandle={loadBillingHandle}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        country={country}
                        setCountry={setCountry}
                        setFieldValue={setFieldValue}
                        State={State}
                        Country={Country}
                        setState={setState}
                        state={state}
                        selectPaymentMethod={selectPaymentMethod}
                        setSelectPaymentMethod={setSelectPaymentMethod}
                        paymentMethod={paymentMethod}
                        viewFlag={viewFlag}
                    />

                    {/* {shipping address} */}
                    <ShippingAddressComp
                        shippingFlag={shippingFlag}
                        setShippingFlag={setShippingFlag}
                        loadShippingHandle={loadShippingHandle}
                        copyBillingHandle={copyBillingHandle}
                        shippingValues={shippingValues}
                        setShippingValues={setShippingValues}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        shipCountry={shipCountry}
                        setShipCountry={setShipCountry}
                        shipState={shipState}
                        setShipState={setShipState}
                        setFieldValue={setFieldValue}
                        Country={Country}
                        State={State}
                        displayShippingPrice={displayShippingPrice}
                        setDisplayShippingPice={setDisplayShippingPice}
                        custShippingFlag={custShippingFlag}
                        applyCustomShippingAmount={applyCustomShippingAmount}
                        removeCustomShippingAmount={removeCustomShippingAmount}
                        viewFlag={viewFlag}
                    />


                    {/* {order details} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Order Details</label>
                        <div className="flex flex-col gap-5">
                            <label
                                className="text-sm text-[#0094FF] cursor-pointer"
                                disabled={viewFlag ? true : false}
                                onClick={() => {
                                    if (!viewFlag) {
                                        setOpenWalletFlag(true)
                                    }
                                }}>
                                Use wallet balance
                            </label>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-start">
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Add Product
                                    </label>
                                    <div className="flex flex-col">
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            isDisabled={order_id ? true : false}
                                            isMulti
                                            placeholder="Select"

                                            value={selectProduct?.length > 0 ? selectProduct : []}

                                            onChange={(selectOption) => {

                                                //console.log("selectOption", selectOption)
                                                setSelectProduct(selectOption)
                                                // setSummaryDataList(selectOption)
                                                //setFieldValue('s_product', selectOption || []);

                                            }}


                                            name="s_product"
                                            className="basic-single sm:text-sm"
                                            options={productOption}
                                            styles={customStyles}
                                        />
                                        {/* {errors?.s_product && touched?.s_product && (
                                            <div className="text-red-600 text-sm">{errors?.s_product}</div>
                                        )} */}
                                        <div className="flex flex-col gap-2 mt-2">
                                            {
                                                variationData?.length > 0 && selectProduct?.length > 0 && variationData?.map((item, index) => {
                                                    return (
                                                        <>
                                                            {
                                                                item?.variations?.length > 0 ?
                                                                    <div className="flex gap-2 items-center " key={index}>
                                                                        <h5 className="text-[12px]">{item?.p_product_sku}</h5>
                                                                        {
                                                                            item?.variations?.map((ele, pos) => {
                                                                                const isSelected = selectedVariations[ele?.p_vari_id];
                                                                                // console.log("isSelected",isSelected)
                                                                                return (
                                                                                    <button
                                                                                        key={pos}
                                                                                        disabled={order_id ? true : false}
                                                                                        onClick={() => {

                                                                                            handleAttributeVariation(ele?.p_vari_id, item, isSelected)
                                                                                            setSelectedVariations((prev) => ({
                                                                                                ...prev,
                                                                                                [ele?.p_vari_id]: !isSelected
                                                                                            }));
                                                                                        }}
                                                                                        type="button"
                                                                                        className={`${isSelected ? "bg-[#3a4b43] text-white" : ele?.p_vari_name ? "bg-white text-[#3a4b43]" : "hidden"} ${order_id ? "opacity-55 cursor-not-allowed " : ""}  px-3 py-1 border border-[#3a4b43] font-bold uppercase text-xs mt-2 `}
                                                                                    >
                                                                                        {ele?.p_vari_name}
                                                                                    </button>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <h6 className="text-[12px] flex gap-2">{item?.p_product_sku} <span className="text-[14px]">No variations</span></h6>
                                                            }
                                                        </>



                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="flex gap-2 w-full items-end">
                                    <div className="flex flex-col w-full">
                                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                            Apply Coupon
                                        </label>
                                        <div className="flex flex-col">
                                            <input
                                                value={values?.coupon}
                                                disabled={viewFlag ? true : false}

                                                //disabled={errorCouponMessage ? true : false}
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    setCouponCode(event.target.value)
                                                }}
                                                // onBlur={handleBlur}

                                                type="text"
                                                name="coupon"
                                                className={`block w-full rounded-md  border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]`}
                                                placeholder="Coupon Code "
                                            />


                                        </div>
                                    </div>

                                    <button type="button"
                                        className={`${viewFlag ? "text-[#fff] bg-gray-400 cursor-not-allowed" : "text-white bg-[#3a4b43]"} rounded-lg px-4  py-2 text-sm font-semibold `}
                                        disabled={viewFlag ? true : false}

                                        onClick={() => {
                                            if (couponRemoveFlag) {
                                                applyCouponVoucher()
                                            } else {
                                                removeCouponVoucher()

                                            }

                                        }}>
                                        {
                                            couponRemoveFlag ? "Apply" : "Remove"
                                        }
                                    </button>

                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-end">
                                {/* <div className="flex gap-2 w-full items-end"> */}
                                <div className="flex flex-col gap-2">
                                    <div className="flex gap-2 flex-col sm:flex-row text-sm font-medium leading-6 text-gray-900">
                                        <label>Custom Discount :-</label>
                                        <div className="flex items-center gap-5">
                                            {
                                                customDiscountRadio.map((ele, index) => {
                                                    return (
                                                        <>
                                                            <div className="flex gap-2 items-center" key={index}>
                                                                <input type="radio"
                                                                    disabled={viewFlag ? true : false}
                                                                    checked={!!selectDiscountRadio[ele.key]}
                                                                    onChange={(e) => handleDiscountRadio(ele.key, e.target.checked)}
                                                                    className="rounded-full  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                <span className="text-sm">{ele.label}</span>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    <div className="flex gap-2">
                                        <input
                                            //value={values}
                                            value={customDiscountAmount}
                                            disabled={viewFlag ? true : false}

                                            onChange={(event) => {
                                                if (selectDiscountRadio.percentage) {
                                                    const valuecheck = event?.target.value > 100 ? 100 : event?.target.value
                                                    setCustomDiscountAmount(valuecheck)
                                                } else {
                                                    setCustomDiscountAmount(event?.target.value)
                                                }


                                            }}

                                            min="0"
                                            max={selectDiscountRadio.percentage ? "100" : ""}
                                            type="number"
                                            name="customDiscountAmount"
                                            className={`block w-full rounded-md  border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]`}
                                            placeholder="Custom Discount"
                                        />
                                        <button type="button"
                                            disabled={viewFlag ? true : false}

                                            className={`${viewFlag ? "text-[#fff] bg-gray-400 cursor-not-allowed" : "text-white bg-[#3a4b43]"} rounded-lg px-4  py-2 text-sm font-semibold text-white bg-[#3a4b43]  `}
                                            onClick={() => {
                                                if (customDiscountFlag) {
                                                    handleCustomDiscount()
                                                } else {
                                                    removeCustomDiscount()
                                                }

                                            }}>
                                            {
                                                customDiscountFlag ? "Apply" : "Remove"
                                            }
                                        </button>


                                    </div>
                                </div>



                                {/* </div> */}
                            </div>
                        </div>
                    </div>



                    {/* {summary} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Summary</label>
                        <div>
                            <SummaryTable columns={summary_columns({ selectCurrency })} data={summaryDataList} />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between  w-full mt-5 gap-5">
                            <div className="basis-2/5">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Coupon
                                    </label>
                                    <div className="">
                                        <input
                                            value={couponCode}
                                            readOnly
                                            disabled={viewFlag ? true : false}

                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name=""
                                            className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="coupon"
                                        />

                                    </div>
                                </div>
                                <div className="mt-5 flex gap-4 items-end">
                                    <div className="">
                                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                            Custom Amount
                                        </label>
                                        <div className="">
                                            <input
                                                value={customAmount}
                                                min="0"
                                                onChange={(e) => setCustomAmount(e.target.value)}
                                                // onBlur={handleBlur}
                                                disabled={viewFlag ? true : false}

                                                type="number"
                                                name="customAmount"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                                placeholder="coupon"
                                            />

                                        </div>
                                    </div>
                                    <div className="">
                                        <button
                                            type="button"
                                            disabled={viewFlag ? true : false}

                                            className={`${viewFlag ? "text-[#fff] bg-gray-400 cursor-not-allowed" : "text-white bg-[#3a4b43]"} rounded-lg px-4 py-2  text-sm font-semibold  `}
                                            onClick={() => {
                                                if (customAmountFlag) {
                                                    applyCustomAmount()
                                                } else {
                                                    removeCustomAmount()

                                                }

                                            }}>
                                            {
                                                customAmountFlag ? "Apply" : "Remove"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="basis-2/5 shadow-md border rounded-lg ">
                                <div className="flex flex-col  p-5 gap-x-5 gap-y-6 ">
                                    <div className="flex justify-between ">
                                        <h4 className="text-sm text-gray-500">Item SubTotal</h4>
                                        <span className="text-sm">

                                            {editItemSubtotal ? global_epp(editItemSubtotal, selectCurrency?.label) : global_epp(itemSubTotal, selectCurrency?.label)
                                            }
                                        </span>
                                    </div>
                                    {

                                        shippingPrice ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Shipping</h4>
                                                <span className="text-sm">
                                                    {
                                                        selectCurrency.label.split(" ")[1] + " " + shippingPrice

                                                    }</span>
                                            </div> : <></>

                                    }
                                    {
                                        customPrice > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Custom Amount</h4>
                                                <span className="text-sm">{
                                                    selectCurrency.label.split(" ")[1] + " " + customPrice
                                                }</span>
                                            </div> : <></>
                                    }
                                    {
                                        couponAmount > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Coupon</h4>
                                                <span className="text-sm">
                                                    -{global_epp(couponAmount, selectCurrency?.label)}</span>
                                            </div> : <></>
                                    }
                                    {
                                        custDiscountPrice > 0 || isNormalAmount > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Custom Discount</h4>
                                                <span className="text-sm">-{selectCurrency.label.split(" ")[1] + " " + (selectDiscountRadio?.percentage ? isNormalAmount : custDiscountPrice)}</span>
                                            </div> : <></>
                                    }
                                    {
                                        parseInt(walletAmount) > 0 ?
                                            <div className="flex justify-between border-t-2 pt-4">
                                                <h4 className="text-sm text-gray-500">Wallet</h4>
                                                <span className="text-sm">-{selectCurrency.label.split(" ")[1] + " " + (walletAmount)}</span>
                                            </div> : <></>
                                    }
                                    <div className="flex justify-between border-t-2 pt-4">
                                        <h4 className="text-md text-black">Order Total</h4>
                                        <span className="text-md">
                                            {
                                                global_epp(totalAmount, selectCurrency?.label)
                                            }

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* {shipment tracking} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <div className="flex justify-between items-center">
                            <h2 className="text-md font-medium text-lg">Shipment Tracking</h2>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShipmentTrackFlag((prev) => !prev)} /></div>
                        </div>
                        <div className={`${shipmentTrackFlag ? "grid grid-cols-1 md:grid-cols-3 gap-5 my-5" : "hidden "}`}>
                            <div>
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Tracking Number
                                </label>
                                <div className="">
                                    <input
                                        value={values.tracking_number}
                                        disabled={viewFlag ? true : false}

                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        // onBlur={handleBlur}
                                        type="text"
                                        name="tracking_number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder="Tracking number"
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Shipping Provider
                                </label>
                                <div>
                                    <Select
                                        value={selectShipProvider}
                                        isDisabled={viewFlag ? true : false}
                                        onChange={(selectOption) => setSelectShipProvider(selectOption)}
                                        className=" sm:text-sm"
                                        options={shippingProvider}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Date Shipped
                                </label>
                                <div className="flex">
                                    <input
                                        type="date"
                                        disabled={viewFlag ? true : false}

                                        value={values.tracking_ship_date}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        name="tracking_ship_date"
                                        className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Marked order as
                                </label>
                                <div className="flex items-center gap-5">
                                    {
                                        markOrder.map((ele, index) => {
                                            return (
                                                <>
                                                    <div className="flex gap-2 items-center" key={index}>
                                                        <input type="radio"
                                                            disabled={viewFlag ? true : false}

                                                            checked={!!selectMarkOrder[ele.key]}
                                                            onChange={(e) => handleMarkOrder(ele.key, e.target.checked)}
                                                            className="rounded-full  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                        <span className="text-sm">{ele.label}</span>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                </div>
                            </div>

                        </div>
                    </div>


                    {/* {order action} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm border-2 border-gray-200">
                        <label className="text-black text-lg font-medium">Order Actions</label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-5">
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Choose Actions
                                </label>
                                <div>
                                    <Select
                                        classNamePrefix="select"
                                        defaultValue="select"
                                        isDisabled={viewFlag ? true : false}
                                        placeholder="Select"
                                        value={selectOrderAction}
                                        // // onBlur={handleBlur}
                                        onChange={(selectOption) => setSelectOrderAction(selectOption)}

                                        className="basic-single sm:text-sm"
                                        options={orderAction}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                            <div className="flex flex-col justify-center">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Email Send
                                </label>
                                <Switch
                                    checked={enabledEmail}
                                    onChange={setEnabledEmail}
                                    disabled={viewFlag ? true : false}

                                    className={`${enabledEmail ? 'bg-teal-900' : 'bg-gray-300'} ${viewFlag ? 'cursor-not-allowed' : 'cursor-pointer'}
          relative inline-flex h-[28px] w-[64px] shrink-0  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={`${enabledEmail ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>

                    <hr className="h-px my-5 bg-gray-300 border-0 "></hr>

                    <div className="flex gap-5 justify-end ">
                        <button
                            onClick={clearForm}
                            disabled={viewFlag ? true : false}

                            type="button"
                            className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                        >
                            Cancel
                        </button>
                        <button

                            type="button"
                            disabled={viewFlag ? true : false}

                            onClick={() => handleSubmit()}
                            className={`${viewFlag ? "text-[#fff] bg-gray-400 cursor-not-allowed" : "text-[#fff] bg-[#3a4b43]"} rounded-md border   shadow-sm   px-4 py-2 text-sm font-medium`}
                        >
                            {submitLoader ?
                                <RotatingLineSpinner /> : "Submit"
                            }
                        </button>
                    </div>
                </form>
            </div>

            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }

            <UseWallet
                setOpenWalletFlag={setOpenWalletFlag}
                openWalletFlag={openWalletFlag}
                walletAmount={walletAmount}
                setWalletAmount={setWalletAmount}
                walletBalance={global_epp(walletBalance, selectCurrency?.label)}
                withDrawHandler={withDrawHandler}
                order_id={order_id}
            />

        </>
    )
}

export default AddOrder