import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import DashboardLayout from "../layout/dashboardLayout";
import HomePage from "../container/pages/home/HomePage";
import Login from "../container/pages/login/Login";
import AddUser from "../container/pages/users/AddUser";
import Orders from "../container/pages/orders/Orders";
import AddOrder from "../container/pages/orders/AddOrder";
import Product from "../container/pages/product/Product";
import AddProduct from "../container/pages/product/AddProduct";
import Category from "../container/pages/category/Category";
import AddCategory from "../container/pages/category/AddCategory";
import Brand from "../container/pages/brands/Brand";
import AddBrand from "../container/pages/brands/AddBrand";
import AddTag from "../container/pages/tags/AddTag";
import TagPage from "../container/pages/tags/TagPage";
import SEOPage from "../container/pages/seo/SEOPage";
import AddSEOPage from "../container/pages/seo/AddSEO";
import ParentAttribute from "../container/pages/parentAttribute/ParentAttribute";
import AddParentAttribute from "../container/pages/parentAttribute/AddParentAttribute";
import ChildAttribute from "../container/pages/childAttribute/ChildAttribute";
import AddChildAttribute from "../container/pages/childAttribute/AddChildAttribute";
import Users from "../container/pages/users/Users";
import Abandoned from "../container/pages/abandoned/Abandoned";
import Voucher from "../container/pages/voucher/Voucher";
import AddVoucher from "../container/pages/voucher/AddVoucher";
import Wallet from "../container/pages/wallet/Wallet";
import AddWalletAmount from "../container/pages/wallet/AddWalletAmount";
import AttributeValue from "../container/pages/attributeValue/AttributeValue";
import AddValue from "../container/pages/attributeValue/AddValue";
import Roles from "../container/pages/roles/Roles";
import EditRolePermission from "../container/pages/roles/EditRolePermission";
import MyContext from "../context/MyContext";
import Banner from "../container/pages/banner/Banner";
import AddBanner from "../container/pages/banner/AddBanner";
import ImageSection from "../container/pages/imageSection/ImageSection";
import AddImageSection from "../container/pages/imageSection/AddImageSection";
import DesignerSection from "../container/pages/designerSection/DesignerSection";
import AddDesignerSection from "../container/pages/designerSection/AddDesignerSection";
import ReEditSection from "../container/pages/reditSection/ReEditSection";
import AddReEditSection from "../container/pages/reditSection/AddReEditSection";
import CelebritySection from "../container/pages/celebritySection/CelebritySection";
import AddCelebritySection from "../container/pages/celebritySection/AddCelebritySection";
import StorySection from "../container/pages/storySection/StorySection";
import AddStorySection from "../container/pages/storySection/AddStorySection";
import NewInSection from "../container/pages/newInSection/NewInSection";
import AddNewInSection from "../container/pages/newInSection/AddNewInSection"
const RoutesConfig = () => {
  return (
    <Router>
      <MyContext>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route element={<ProtectedRoute />}> */}
          <Route element={<ProtectedRoute moduleName="Dashboard" />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Users" />}>
            <Route path="/user-page" element={<Users />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Products" />}>
            <Route path="/product-page" element={<Product />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Orders" />}>
            <Route path="/order-page" element={<Orders />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Category" />}>
            <Route path="/category-page" element={<Category />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Brands" />}>
            <Route path="/brand-page" element={<Brand />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Parent Attribute" />}>
            <Route path="/parentattribute" element={<ParentAttribute />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Child Attribute" />}>
            <Route path="/childAttribute" element={<ChildAttribute />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Values" />}>
            <Route path="/attributevalues" element={<AttributeValue />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Abandoned" />}>
            <Route path="/abandoned" element={<Abandoned />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Voucher" />}>
            <Route path="/voucher" element={<Voucher />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Wallet" />}>
            <Route path="/wallet" element={<Wallet />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Roles" />}>
            <Route path="/roles" element={<Roles />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Banner" />}>
            <Route path="/banner" element={<Banner />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="ImageSection" />}>
            <Route path="/imagesection" element={<ImageSection />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="DesignerSection" />}>
            <Route path="/designersection" element={<DesignerSection />} />
          </Route>

          <Route element={<ProtectedRoute moduleName="ReEditSection" />}>
            <Route path="/reeditsection" element={<ReEditSection />} />
          </Route>

          <Route element={<ProtectedRoute moduleName="CelebritySection" />}>
            <Route path="/celebritysection" element={<CelebritySection />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="StorySection" />}>
            <Route path="/storysection" element={<StorySection />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="NewInSection" />}>
            <Route path="/newinsection" element={<NewInSection />} />
          </Route>

          {/* Orders  */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Orders"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-order" element={<AddOrder />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Orders"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/editorder/:order_id" element={<AddOrder />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Orders" />}>
            <Route path="/vieworder/:order_id" element={<AddOrder />} />
          </Route>

          {/*Users  */}
          <Route
            element={
              <ProtectedRoute moduleName="Users" mandatoryPermission="is_add" />
            }
          >
            <Route path="/add-user" element={<AddUser />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Users"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/edituser/:user_id" element={<AddUser />} />
          </Route>

          {/* Products */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Products"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-product" element={<AddProduct />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Products"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/editproduct/:product_id" element={<AddProduct />} />
          </Route>
          <Route element={<ProtectedRoute moduleName="Products" />}>
            <Route path="/viewproduct/:product_id" element={<AddProduct />} />
          </Route>

          {/* Category */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Category"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-category" element={<AddCategory />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Category"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/editcategory/:category_id"
              element={<AddCategory />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Category"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/editSubCategory/:category_id"
              element={<AddCategory />}
            />
          </Route>

          {/* Brands */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Brands"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-brand" element={<AddBrand />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Brands"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/editbrand/:brand_id" element={<AddBrand />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Brands"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/editSubBrand/:brand_id" element={<AddBrand />} />
          </Route>

          {/* Attributes */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Parent Attribute"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route
              path="/add-parentAttribute"
              element={<AddParentAttribute />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Parent Attribute"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-parentAttribute/:parent_id"
              element={<AddParentAttribute />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Child Attribute"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-childAttribute" element={<AddChildAttribute />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Child Attribute"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-childAttribute/:child_id"
              element={<AddChildAttribute />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Values"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-values" element={<AddValue />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Values"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-values/:attributeValue_id"
              element={<AddValue />}
            />
          </Route>

          {/* Voucher */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Voucher"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-voucher" element={<AddVoucher />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Voucher"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/editVoucher/:voucher_id" element={<AddVoucher />} />
          </Route>

          {/* Wallet */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Wallet"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-wallet" element={<AddWalletAmount />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Wallet"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-wallet/:wallet_id"
              element={<AddWalletAmount />}
            />
          </Route>

          {/* Roles */}

          <Route element={<ProtectedRoute moduleName="Roles" />}>
            <Route
              path="/role-info/:role_id"
              element={<EditRolePermission />}
            />
          </Route>

          {/* Banner */}
          <Route
            element={
              <ProtectedRoute
                moduleName="Banner"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-banner" element={<AddBanner />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Banner"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route path="/edit-banner/:banner_id" element={<AddBanner />} />
          </Route>

          {/* ImageSection */}
          <Route
            element={
              <ProtectedRoute
                moduleName="ImageSection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-imagesection" element={<AddImageSection />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="Banner"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-imagesection/:image_id"
              element={<AddImageSection />}
            />
          </Route>

          {/* DesignerSection */}
          <Route
            element={
              <ProtectedRoute
                moduleName="DesignerSection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route
              path="/add-designersection"
              element={<AddDesignerSection />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="DesignerSection"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-designersection/:designer_id"
              element={<AddDesignerSection />}
            />
          </Route>

          {/* ReditSection */}
          <Route
            element={
              <ProtectedRoute
                moduleName="ReEditSection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-reditsection" element={<AddReEditSection />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="ReEditSection"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-reditsection/:redit_id"
              element={<AddReEditSection />}
            />
          </Route>

          {/* Celebrity section */}
          <Route
            element={
              <ProtectedRoute
                moduleName="CelebritySection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route
              path="/add-celebritysection"
              element={<AddCelebritySection />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="CelebritySection"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-celebritysection/:celebrity_id"
              element={<AddCelebritySection />}
            />
          </Route>

          {/* Story Section */}
          <Route
            element={
              <ProtectedRoute
                moduleName="StorySection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-storysection" element={<AddStorySection />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="StorySection"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-storysection/:story_id"
              element={<AddStorySection />}
            />
          </Route>

          {/* New In Section */}
          <Route
            element={
              <ProtectedRoute
                moduleName="NewInSection"
                mandatoryPermission="is_add"
              />
            }
          >
            <Route path="/add-newinsection" element={<AddNewInSection />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                moduleName="NewInSection"
                mandatoryPermission="is_edit"
              />
            }
          >
            <Route
              path="/edit-newinsection/:new_id"
              element={<AddNewInSection />}
            />
          </Route>
        </Routes>
      </MyContext>
    </Router>
  );
};
export default RoutesConfig;
