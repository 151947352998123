import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import Table from "../../../components/tables/table";
import Spinner from "../../../components/Spinner";
import MasterServices from "../../../ApiServices/MasterServices";
import { roles_columns } from "../../../components/tables/tableheader";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { RotatingLineSpinner } from "../../../components/Spinner";
import { toast } from "react-toast";

const Roles = () => {
    const pages = [{ title: "Roles", href: "/roles", module_id: 1 }];
    const [rolesData, setRolesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [editRole, setEditRole] = useState([]);
   
    const [roleId, setRoleId] = useState({
        id: "",
        name: ""
    });

    const formValues = {
        user_role_name: "",

    }
    const [initialValues, setInitialValues] = useState(formValues)

    useEffect(() => {
        const fetchData = async () => {
            getAllRole()
        }
        fetchData()
    }, [])

    const getAllRole = async () => {
        try {
            const response = await MasterServices.getAllRolesApi();
            console.log("getAllRole", response)
            if (response?.data?.data?.length > 0) {
                setRolesData(response?.data?.data)
                setLoading(false)
            } else {
                setRolesData([]);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log("getAllUserRole", error)
        }
    }
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,

    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,

        onSubmit: async (values) => {


            if (roleId?.id) {
                const body = {
                    user_role_name: values.user_role_name,
                    user_role_id: roleId?.id
                }
                console.log("body", body)
                editRoles(body)
            } else {
                const body = {
                    user_role_name: values.user_role_name,

                }
                console.log("body", body)
                addRoles(body)
            }


        }
    })

    const editRoles = async(payload)=>{
        try {
            setSubmitLoader(true)
            const response = await MasterServices.editRolesApi(payload);
            console.log("response", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                setOpenDrawer(false);
                window.location.reload()

            } else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
        }
    }
    const addRoles = async (payload) => {
        try {
            setSubmitLoader(true)
            const response = await MasterServices.addRolesApi(payload);
            console.log("response", response)
            if (response?.data?.success) {
                setSubmitLoader(false)
                toast.success(response?.data?.message)
                setOpenDrawer(false);
                window.location.reload()

            } else {
                setSubmitLoader(false)
                toast.info(response?.data?.message)
            }
        }
        catch (error) {
            setSubmitLoader(false)
            toast.error(error?.data?.message)
        }
    }


    const editRoleDataHandler = (id, name) => {
        console.log("editRoleDataHandler", id, name)
        setOpenDrawer(true);
        setRoleId({
            id: id,
            name: name
        })
    }



    useEffect(() => {
        if (roleId?.id && roleId?.name) {
            setInitialValues({
                user_role_name: roleId?.name
            })
        }
    }, [roleId])

    return (
        <>
            <Breadcrumb pages={pages} />
            <div className=" flex items-center justify-between my-2">
                <div>
                    <h1 className="text-xl font-semibold text-gray-900">Roles List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the Roles.</p>
                </div>
                <button

                    onClick={() => setOpenDrawer(true)}
                    className=" rounded-md flex items-center uppercase px-3 py-2 text-sm font-semibold text-[#3a4b43] bg-white shadow-md hover:bg-[#3a4b43] hover:text-white"
                >
                    <label>Add Roles</label>
                    <PlusCircleIcon className="w-6 h-6" />
                </button>
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    <Table
                        columns={roles_columns({ editRoleDataHandler })}
                        data={rolesData}
                    />
            }


            <Dialog open={openDrawer} onClose={() => {
                setOpenDrawer(false)

            }} className="relative z-50">
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-sm transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className="flex h-full flex-col overflow-y-scroll bg-white  shadow-xl">
                                    <div className="px-4 sm:px-6 bg-[#3a4b43] py-5">
                                        <div className="flex items-start justify-between ">
                                            <DialogTitle className="text-base font-semibold text-white">{roleId?.id ? "Edit Role" : "Add Role"}</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOpenDrawer(false)

                                                    }}
                                                    className="relative rounded-full bg-white text-gray-500 hover:text-gray-500 "
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <form className="flex flex-col gap-4"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit()
                                            }}>
                                            <div>
                                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                                    Role Name
                                                </label>
                                                <div className="flex flex-col">
                                                    <input
                                                        value={values.user_role_name}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        type="text"
                                                        name="user_role_name"
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                                        placeholder="Role name"
                                                    />
                                                    {/* {errors.firstName && touched.firstName && (
                                                        <div className="text-red-600 text-sm">{errors.firstName}</div>
                                                    )} */}

                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => handleSubmit()}
                                                className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                                            >
                                                {submitLoader ?
                                                    <RotatingLineSpinner /> : "Submit"
                                                }

                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default Roles